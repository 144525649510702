import React from "react";
import PropTypes from "prop-types";

const OpenExternalBroswerModal = ({ onClose, children }) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: "1000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {children}
        <button
          onClick={handleClose}
          style={{ marginTop: "20px", borderRadius: "10px", color: "red" }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

OpenExternalBroswerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default OpenExternalBroswerModal;
