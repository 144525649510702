import React, { useContext, useRef, useEffect } from "react";
import { MediaContext } from "../../context/MediaContext";
import MediaCard from "../MediaCard/MediaCard";

const UserVideo = () => {
  const {
    mediaStream,
    audioEnabled,
    videoEnabled,
    setAudioEnabled,
    setVideoEnabled,
  } = useContext(MediaContext);

  const videoRef = useRef(null);
  const audioRef = useRef(null);

  useEffect(() => {
    if (mediaStream) {
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
      if (audioRef.current) {
        audioRef.current.srcObject = mediaStream;
      }
    }
  }, [mediaStream]);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <MediaCard
        audioRef={audioRef}
        videoRef={videoRef}
        type="local"
        audioEnabled={audioEnabled}
        videoEnabled={videoEnabled}
        setAudioEnabled={setAudioEnabled}
        setVideoEnabled={setVideoEnabled}
      />
    </div>
  );
};

export default UserVideo;
