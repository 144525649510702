import React, { useState, useEffect } from "react";
import { Fab, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PostItemDialog from "../PostItemDialog/PostItemDialog";
import useFetchShop from "../../hooks/useFetchShop";
import SkeletonLoader from "./ShimmerEffect/ShimmerLoader";
import ProductCard from "./ShopCards/ProductCard/ProductCard";
import ServiceCard from "./ShopCards/ServiceCard/ServiceCard";
import CSS from "./Shop.module.css";
import { Tab, Tabs, Box } from "@mui/material";
import debounce from "lodash.debounce";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import NothingFoundGif from "../../assets/images/NunFound.gif";

const Shop = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);

  const { user } = useAuth0();

  const [userPostedProducts, setUserPostedProducts] = useState([]);
  const [userPostedServices, setUserPostedServices] = useState([]);

  const [filteredProductsLoaded, setFilteredProductsLoaded] = useState(false);
  const [filteredServicesLoaded, setFilteredServicesLoaded] = useState(false);

  const [showNoResultsMessageProducts, setShowNoResultsMessageProducts] =
    useState(false);
  const [showNoResultsMessageServices, setShowNoResultsMessageServices] =
    useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [updateProductsFlag, setUpdateProductsFlag] = useState(false);
  const [updateServicesFlag, setUpdateServicesFlag] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);

  const productsApiUrl = "/shop/products";
  const servicesApiUrl = "/shop/services";

  const isMobile = useMediaQuery("(max-width:600px)");

  const navigate = useNavigate();

  const NothingFound = () => {
    return (
      <>
        <img
          src={NothingFoundGif}
          alt="Loading..."
          className={CSS.nothingFoundGif}
        />
        <p
          style={{
            textAlign: "center",
            marginTop: "320px",
            color: "white",
          }}
        >
          {" "}
          Nothing posted yet for this college yet, be the forerunner and put
          something up!
        </p>
      </>
    );
  };

  const {
    shopData: fetchedProducts,
    isShopLoading: isLoadingProducts,
    // eslint-disable-next-line no-unused-vars
    error: errorLoadingProducts,
  } = useFetchShop(productsApiUrl, updateProductsFlag);

  const {
    shopData: fetchedServices,
    isShopLoading: isLoadingServices,
    // eslint-disable-next-line no-unused-vars
    error: errorLoadingServices,
  } = useFetchShop(servicesApiUrl, updateServicesFlag);

  useEffect(() => {
    if (products) {
      setFilteredProductsLoaded(true);
    }

    if (services) {
      setFilteredServicesLoaded(true);
    }
  }, [products, services]);

  useEffect(() => {
    if (fetchedProducts) {
      setProducts(fetchedProducts);
    }
    if (fetchedServices) {
      setServices(fetchedServices);
    }
  }, [fetchedProducts, fetchedServices]);

  useEffect(() => {
    if (user && user.email) {
      const userProducts = filteredProducts.filter(
        (product) => product.email === user.email
      );
      setUserPostedProducts(userProducts);

      const userServices = filteredServices.filter(
        (service) => service.email === user.email
      );
      setUserPostedServices(userServices);
    }
  }, [filteredProducts, filteredServices, user]);

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      let noResultsProducts = false;
      let noResultsServices = false;

      if (!searchQuery) {
        setFilteredProducts(products);
        setFilteredServices(services);
      } else {
        const refinedQuery = searchQuery.toLowerCase();
        const filteredProducts = products.filter(
          (product) =>
            product.title.toLowerCase().includes(refinedQuery) ||
            product.subcategory.toLowerCase().includes(refinedQuery) ||
            product.description.toLowerCase().includes(refinedQuery)
        );
        const filteredServices = services.filter(
          (service) =>
            service.title.toLowerCase().includes(refinedQuery) ||
            service.subcategory.toLowerCase().includes(refinedQuery) ||
            service.description.toLowerCase().includes(refinedQuery) ||
            (service.active && "available now".includes(refinedQuery))
        );

        setFilteredProducts(filteredProducts);
        setFilteredServices(filteredServices);

        noResultsProducts = filteredProducts.length === 0;
        noResultsServices = filteredServices.length === 0;
      }

      // Reset and then set delay for showing no results message
      setShowNoResultsMessageProducts(false);
      setShowNoResultsMessageServices(false);

      setTimeout(() => {
        setShowNoResultsMessageProducts(noResultsProducts);
        setShowNoResultsMessageServices(noResultsServices);
      }, 700); // Adjust the delay as needed
    }, 300);

    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchQuery, products, services]);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSavedPostsClick = () => {
    navigate("/user-posts", {
      state: { userPostedProducts, userPostedServices },
    });
  };

  const addNewProduct = (newProduct) => {
    setProducts((prevProducts) => [newProduct, ...prevProducts]);
    setUpdateProductsFlag((flag) => !flag);
  };

  const addNewService = (newService) => {
    setServices((prevServices) => [newService, ...prevServices]);
    setUpdateServicesFlag((flag) => !flag);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const NoResultsMessage = ({ itemType }) => (
    <div style={{ textAlign: "center", marginTop: "20px", color: "white" }}>
      <p>
        Oops no {itemType} match search. Umm, try searching with something
        else...
      </p>
    </div>
  );

  NoResultsMessage.propTypes = {
    itemType: PropTypes.string.isRequired,
  };

  const tabsStyle = {
    borderBottom: 1,
    borderColor: "divider",
    marginTop: isMobile ? "60px" : "0px",
  };

  const searchAndBookmarkStyle = {
    display: "flex", // Use flexbox for alignment
    alignItems: "center", // Align items vertically
    position: "fixed",
    top: isMobile ? "90px" : "90px",
    right: isMobile ? "14%" : "70px",
    zIndex: 2,
  };

  const bookmarkIconStyle = {
    marginLeft: "10px", // Space between the search field and icon
    color: "white",
  };

  return (
    <div className={CSS.shopContainer}>
      <div style={searchAndBookmarkStyle}>
        <TextField
          label="Search..."
          variant="outlined"
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <div
          className={CSS.savedPostsContainer}
          onClick={handleSavedPostsClick}
        >
          <span className={CSS.savedPostsText}>Posts</span>

          <BookmarkIcon style={bookmarkIconStyle} />
        </div>
      </div>
      <div className={CSS.shopContent}>
        <Box sx={tabsStyle}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="shop tabs"
          >
            <Tab label="Products" />
            <Tab label="Services" />
          </Tabs>
        </Box>

        {activeTab === 0 && (
          <section className={CSS.section}>
            {isLoadingProducts ? (
              <SkeletonLoader count={25} />
            ) : errorLoadingProducts && !filteredProductsLoaded ? (
              <div style={{ color: "white" }}>Error loading products...</div>
            ) : filteredProducts.length > 0 ? (
              <div className={CSS.cardContainer}>
                {filteredProducts
                  .slice()
                  .reverse()
                  .map((product) => (
                    <ProductCard key={product.id} product={product} />
                  ))}
              </div>
            ) : !isLoadingProducts &&
              !errorLoadingProducts &&
              filteredProducts.length === 0 &&
              showNoResultsMessageProducts ? (
              <NoResultsMessage
                style={{ color: "white" }}
                itemType="products"
              />
            ) : (
              <>
                <NothingFound />
              </>
            )}
          </section>
        )}

        {activeTab === 1 && (
          <section className={CSS.section}>
            {isLoadingServices ? (
              <SkeletonLoader count={35} />
            ) : errorLoadingServices && !filteredServicesLoaded ? (
              <div style={{ color: "white" }}>Error loading services...</div>
            ) : filteredServices.length > 0 ? (
              <div className={CSS.cardContainer}>
                {filteredServices
                  .slice()
                  .reverse()
                  .map((service) => (
                    <ServiceCard key={service.id} service={service} />
                  ))}
              </div>
            ) : !isLoadingServices &&
              !errorLoadingServices &&
              filteredServices.length === 0 &&
              showNoResultsMessageServices ? (
              <NoResultsMessage itemType="services" />
            ) : (
              <>
                <NothingFound />
              </>
            )}
          </section>
        )}
      </div>

      <Fab
        color="primary"
        aria-label="add"
        style={{ position: "fixed", bottom: 70, right: 40 }}
        onClick={handleOpenDialog}
      >
        <AddIcon />
      </Fab>
      <PostItemDialog
        open={isDialogOpen}
        handleClose={handleCloseDialog}
        addNewProduct={addNewProduct}
        addNewService={addNewService}
      />
    </div>
  );
};

export default Shop;
