import React, { useState, useEffect } from "react";
import "./LandingPage.css";
import Landing from "./Landing";
import OpenExternalBroswerModal from "./OpenExternalBroswerModal";

const LandingPage = () => {
  const [showModal, setShowModal] = useState(false);
  const isInAppBrowser = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /Instagram|FBAN|FBAV/i.test(userAgent);
  };
  const videoRef = useState(null);

  useEffect(() => {
    if (isInAppBrowser()) {
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = 0.2;
    }
  }, [videoRef]);

  return (
    <div className="landingPage maxWidth">
      {showModal && (
        <OpenExternalBroswerModal onClose={() => setShowModal(false)}>
          <p>
            Open in external browser. Click on top right button to open in
            external broswer 👆
          </p>
        </OpenExternalBroswerModal>
      )}
      <Landing />
    </div>
  );
};

export default LandingPage;
