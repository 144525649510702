import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Chip,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import PropTypes from "prop-types";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailIcon from "@mui/icons-material/Mail";
import CSS from "./ServiceCard.module.css?v=1";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { formatDistanceToNow } from "date-fns";
import Slider from "react-slick";
import Tooltip from "@mui/material/Tooltip";
import noImageFound from "../../../../assets/images/noImageFound.jpeg";

const ServiceCard = ({ service }) => {
  const [showContact, setShowContact] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipOpen = () => setOpenTooltip(true);
  const handleTooltipClose = () => setOpenTooltip(false);

  const handleContactClick = () => {
    setShowContact(true);
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const humanizeTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const truncate = (string, num) => {
    return string.length > num ? (
      <>
        {string.slice(0, num)}
        <span style={{ color: "darkgray" }}>...</span>
      </>
    ) : (
      string
    );
  };

  const formatPrice = (price) => {
    // Assuming price is a number or a string that can be converted to a number
    const numberPrice = Number(price);
    if (isNaN(numberPrice)) return price; // Return the original price if it's not a valid number

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(numberPrice);
  };

  const handleDotClick = (e) => {
    e.stopPropagation(); // Prevents the event from propagating to the parent card
    // Add any other logic you want to execute when the dot is clicked
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]} ${match[3]}`;
    }
    return null;
  };

  const renderServicesList = () => {
    if (service.services.length > 1) {
      return (
        <div>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={8}>
              <Typography variant="body1" className={CSS.cardServiceType}>
                {service.services[0].type}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                className={CSS.cardPrice}
                variant="body2"
                style={{ fontWeight: "bold" }}
              >
                {formatPrice(service.services[0].rate)}{" "}
                <span style={{ color: "gray", cursor: "pointer" }}>
                  ...more
                </span>
              </Typography>
            </Grid>
          </Grid>
        </div>
      );
    } else if (service.services.length === 1) {
      return (
        <Grid
          container
          alignItems="center"
          spacing={2}
          style={{ marginLeft: "-11px" }}
        >
          <Grid item xs={8}>
            <Typography className={CSS.cardServiceType} variant="body1">
              {service.services[0].type}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              className={CSS.cardPrice}
              variant="body2"
              style={{ fontWeight: "bold" }}
            >
              {formatPrice(service.services[0].rate)}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const renderModalContent = () => (
    <Box className={CSS.modalBoxStyle}>
      <Card>
        <div
          className={CSS.activeDot}
          style={{ backgroundColor: service.active ? "lightgreen" : "red" }}
        />
        {service.images && service.images.length > 0 && (
          <Slider {...sliderSettings}>
            {service.images.map((image, index) => (
              <CardMedia
                key={index}
                component="img"
                image={image}
                alt={service.title}
                className={CSS.serviceImageModal}
              />
            ))}
          </Slider>
        )}
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {service.title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className={CSS.serviceDescription}
          >
            {service.description}
          </Typography>
          <Chip
            label={service.subcategory}
            variant="outlined"
            style={{
              marginTop: "4px",
              marginBottom: "10px",
              backgroundColor: "gray",
            }}
          />
          <Typography variant="body2" className={CSS.contactNumber}>
            {service.instagramHandle && (
              <a
                href={`https://www.instagram.com/${service.instagramHandle}`}
                target="_blank"
                rel="noopener noreferrer"
                className={CSS.instagramIcon}
              >
                <InstagramIcon
                  style={{ color: "#E1306C", fontSize: "1.5em" }}
                />
              </a>
            )}
          </Typography>
          <div className={CSS.contactInfo}>
            <a
              href={`tel:${service.contactNumber}`}
              className={CSS.cardContact}
            >
              {formatPhoneNumber(service.contactNumber)}
            </a>
          </div>
          <List style={{ marginLeft: "-10px" }}>
            {service.services.length > 0 &&
              service.services.map((s, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={8}>
                        <Typography variant="body1">{s.type}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                          className={CSS.cardPrice}
                        >
                          {formatPrice(s.rate)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                  {index < service.services.length - 1 && (
                    <Divider style={{ margin: "8px 0" }} />
                  )}
                </React.Fragment>
              ))}
          </List>
          <Typography className={CSS.postedBy} variant="body2">
            Posted by: {service.postedBy}
          </Typography>
          <Typography className={CSS.postedAt} variant="body2">
            {humanizeTimestamp(service.timestamp)}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );

  return (
    <>
      <Card
        className={CSS.serviceCardContainer}
        style={{
          transition: "transform 0.3s ease",
        }}
        onClick={handleOpenModal}
      >
        <Tooltip
          title={service.active ? "Available now" : "Not Available Now"}
          open={openTooltip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <div
            className={CSS.activeDot}
            style={{ backgroundColor: service.active ? "lightgreen" : "red" }}
            onMouseEnter={handleTooltipOpen}
            onMouseLeave={handleTooltipClose}
            onClick={handleDotClick}
          />
        </Tooltip>
        {service.images && service.images.length > 0 ? (
          <Slider {...sliderSettings}>
            {service.images.map((image, index) => (
              <CardMedia
                key={index}
                component="img"
                image={image}
                alt={service.title}
                className={CSS.serviceImage}
              />
            ))}
          </Slider>
        ) : (
          <Slider {...sliderSettings}>
            <div className={CSS.imageSlide}>
              <img
                src={noImageFound}
                alt={`${service.title}`}
                className={CSS.serviceImage}
              />
            </div>
          </Slider>
        )}

        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {service.title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className={CSS.serviceDescription}
          >
            {truncate(service.description, 23)}
          </Typography>
          <Chip
            label={service.subcategory}
            variant="outlined"
            style={{
              marginTop: "4px",
              marginBottom: "10px",
              backgroundColor: "gray",
              ...(window.innerWidth < 600
                ? { fontSize: "0.7rem", height: "26px" }
                : {}),
            }}
          />
          <Typography
            variant="body2"
            className={CSS.contactNumber}
          ></Typography>
          <div className={CSS.contactInfo}>
            {!showContact ? (
              <div className={CSS.contactLink} onClick={handleContactClick}>
                <MailIcon style={{ fontSize: "1.2em", marginRight: "5px" }} />
                Contact me
              </div>
            ) : (
              <a
                href={`tel:${service.contactNumber}`}
                className={CSS.cardContact}
              >
                {formatPhoneNumber(service.contactNumber)}
              </a>
            )}
            {service.instagramHandle && (
              <a
                href={`https://www.instagram.com/${service.instagramHandle}`}
                target="_blank"
                rel="noopener noreferrer"
                className={CSS.instagramIcon}
              >
                <InstagramIcon
                  style={{ color: "#E1306C", fontSize: "1.5em" }}
                />
              </a>
            )}
          </div>

          {renderServicesList()}
          <Typography className={CSS.postedBy} variant="body2">
            Posted by: {service.postedBy}
          </Typography>
        </CardContent>
      </Card>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="service-modal-title"
        aria-describedby="service-modal-description"
      >
        {renderModalContent()}
      </Modal>
    </>
  );
};

ServiceCard.propTypes = {
  service: PropTypes.node.isRequired,
};

export default ServiceCard;
