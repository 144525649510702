import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Container,
  Box,
  Typography,
  useTheme,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";
import DEMO_PHOTO from "../../assets/images/demophoto.png";
import {
  APP_NAME,
  HORNET_TRIBUNE_FEATURE,
  PRIVACY_POLICY,
  NOTION_WHY_WE_STARTED,
  NOTION_TERMS_OF_SERVICE,
  CONTACT_EMAIL,
} from "../../constants/constants";

const HeaderNav = styled("nav")(({ theme }) => ({
  marginLeft: "auto",
  display: "flex",
  gap: theme.spacing(1),
  [theme.breakpoints.up("sm")]: {
    gap: theme.spacing(2),
  },
}));

const MainContainer = styled(Container)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(6, 2),
  [theme.breakpoints.up("sm")]: { padding: theme.spacing(12, 2) },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const WelcomeSection = ({ DEMO_PHOTO }) => {
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        container
        direction="column"
        justifyContent="center"
        spacing={2}
      >
        <Grid item>
          <Headline
            sx={{ fontFamily: "'Chalkboard SE', 'Comic Sans MS', cursive" }}
          >
            Safe space for meaningful campus connections
          </Headline>
          <SubText>
            Video chat with random students from different campuses and make
            meaningful connections with people you vibe with!
            <p id="landingImageSubfooter"> 🤝 🥳 🗣️ 📚 🎉 🤩 💻 🧠 📖 💐</p>
          </SubText>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={6}>
            <RouterLink
              to="/login"
              style={{
                textDecoration: "none",
                display: "block",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                fullWidth
                sx={{ backgroundColor: "#58A6FF", color: "#0D1117" }}
              >
                Start Chat
              </Button>
            </RouterLink>
          </Grid>
          <Grid item xs={12} sm={6}>
            <a
              href={`mailto:${CONTACT_EMAIL}`}
              style={{
                textDecoration: "none",
                display: "block",
                width: "100%",
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="contained"
                fullWidth
                sx={{ backgroundColor: "#58A6FF", color: "#0D1117" }}
              >
                Get In Touch
              </Button>
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <div className="media-container">
          <img className="laptop" src={DEMO_PHOTO} />
        </div>
      </Grid>
    </Grid>
  );
};

WelcomeSection.propTypes = {
  videoRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  DEMO_PHOTO: PropTypes.string.isRequired,
};

const Headline = styled("h1")(({ theme }) => ({
  fontSize: "1.875rem",
  color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: theme.typography.lineHeightTighter,
  [theme.breakpoints.up("sm")]: {
    fontSize: "3.75rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "4rem",
  },
}));

const SubText = styled("p")(({ theme }) => ({
  color: theme.palette.text.secondary,
  maxWidth: "600px",
  [theme.breakpoints.up("md")]: {
    fontSize: "1.25rem",
  },
}));

const handleButtonClick = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export default function Landing() {
  const theme = useTheme();
  const videoRef = useRef(null);

  return (
    <>
      <Box
        sx={{
          padding: theme.spacing(1),
          margin: theme.spacing(3),
          height: "4rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            color: "#3f51b5",
            fontSize: "2.0rem",
            fontWeight: "bold",
            fontFamily: "'Chalkboard SE', 'Comic Sans MS', cursive",
          }}
        >
          <RouterLink
            to="/"
            style={{ textDecoration: "none", color: theme.palette.primary }}
          >
            {APP_NAME}
          </RouterLink>
        </Typography>
        <HeaderNav>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#58A6FF", color: "#0D1117" }}
            onClick={() => handleButtonClick(NOTION_WHY_WE_STARTED)}
          >
            Learn More
          </Button>
        </HeaderNav>
      </Box>
      <MainContainer>
        <WelcomeSection videoRef={videoRef} DEMO_PHOTO={DEMO_PHOTO} />
      </MainContainer>
      <FooterComponent />
    </>
  );
}

function FooterComponent() {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 5,
        py: 1.5,
        width: "100%",
        borderTop: 1,
        borderColor: "divider",
        alignItems: "center",
        px: 2,
        mt: "auto",
        [theme.breakpoints.up("sm")]: {
          flexDirection: "row",
          py: 1.5,
          px: 3,
        },
      }}
    >
      <Typography
        variant="caption"
        color="text.secondary"
        fontSize="1.5rem"
        sx={{
          textAlign: "center",
          [theme.breakpoints.up("sm")]: { textAlign: "left" },
        }}
      >
        2023 VibeU
      </Typography>
      <a
        href={HORNET_TRIBUNE_FEATURE}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Typography
          variant="caption"
          color="text.secondary"
          fontSize="1.5rem"
          sx={{ "&:hover": { textDecoration: "underline" } }}
        >
          Media
        </Typography>
      </a>
      <a
        href={NOTION_TERMS_OF_SERVICE}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Typography
          variant="caption"
          color="text.secondary"
          fontSize="1.5rem"
          sx={{ "&:hover": { textDecoration: "underline" } }}
        >
          Terms of Service
        </Typography>
      </a>
      <a
        href={PRIVACY_POLICY}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Typography
          variant="caption"
          color="text.secondary"
          fontSize="1.5rem"
          sx={{ "&:hover": { textDecoration: "underline" } }}
        >
          Privacy Policy
        </Typography>
      </a>
    </Box>
  );
}
