import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Tab, Tabs, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import CSS from "./UserPosts.module.css";
import EditableProductCard from "./EditableProductCard/EditableProductCard";
import EditableServiceCard from "./EditableServiceCard/EditableServiceCard";

const UserPosts = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Destructure and default to empty arrays if not provided
  const { userPostedProducts = [], userPostedServices = [] } =
    location.state || {};
  // Initialize state with values passed through location.state
  const [products, setProducts] = useState(userPostedProducts);
  const [services, setServices] = useState(userPostedServices);
  const [activeTab, setActiveTab] = useState(0);

  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    // Update state if location.state changes, for example, if UserPosts is re-rendered with new location.state data
    setProducts(userPostedProducts);
    setServices(userPostedServices);
  }, [userPostedProducts, userPostedServices]);

  const handleBackClicked = () => {
    navigate("/shop");
  };

  const handleTabChange = (e, newValue) => {
    setActiveTab(newValue);
  };

  const handleItemUpdate = (updatedItem) => {
    if (updatedItem.category === "product") {
      setProducts((currentProducts) =>
        currentProducts.map((product) =>
          product._id === updatedItem._id ? updatedItem : product
        )
      );
    } else {
      setServices((currentServices) =>
        currentServices.map((service) =>
          service._id === updatedItem._id ? updatedItem : service
        )
      );
    }
  };

  const NoResultsMessage = ({ itemType }) => (
    <div style={{ textAlign: "center", marginTop: "20px", color: "white" }}>
      <p>You have not posted any {itemType} yet</p>
    </div>
  );

  NoResultsMessage.propTypes = {
    itemType: PropTypes.string.isRequired,
  };

  const tabsStyle = {
    borderBottom: 1,
    borderColor: "divider",
    marginTop: isMobile ? "60px" : "0px",
  };

  const arrowBack = {
    marginLeft: "20px",
    color: "white",
    marginBottom: "-80px",
  };

  return (
    <>
      <ArrowBackIcon sx={arrowBack} onClick={handleBackClicked} />
      <div className={CSS.userPostsContent}>
        <Box sx={tabsStyle}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="shop tabs"
          >
            <Tab label="Products" />
            <Tab label="Services" />
          </Tabs>
        </Box>

        {activeTab === 0 && (
          <section className={CSS.section}>
            {products.length > 0 ? (
              <div className={CSS.cardContainer}>
                {products
                  .slice()
                  .reverse()
                  .map((product) => {
                    return (
                      <EditableProductCard
                        key={product.id}
                        product={product}
                        onProductUpdate={handleItemUpdate}
                      />
                    );
                  })}
              </div>
            ) : (
              <NoResultsMessage itemType="products" />
            )}
          </section>
        )}

        {activeTab === 1 && (
          <section className={CSS.section}>
            {services.length > 0 ? (
              <div className={CSS.cardContainer}>
                {services
                  .slice()
                  .reverse()
                  .map((service) => (
                    <EditableServiceCard
                      key={service.id}
                      service={service}
                      onServiceUpdate={handleItemUpdate}
                    />
                  ))}
              </div>
            ) : (
              <NoResultsMessage itemType="services" />
            )}
          </section>
        )}
      </div>
    </>
  );
};

export default UserPosts;
