import { useContext } from "react";
import PropTypes from "prop-types";
import { RoomContext } from "../context/RoomContext";
import { MediaContext } from "../context/MediaContext";
import { useIdleTimer } from "react-idle-timer";

const IdleTimerWrapper = ({ timeout, onIdle }) => {
  const { releaseMedia } = useContext(MediaContext);
  const { roomName } = useContext(RoomContext);

  useIdleTimer({
    timeout,
    onIdle:
      roomName === null
        ? async () => {
            onIdle();
            await releaseMedia();
          }
        : undefined,
    debounce: 500,
  });

  return null;
};

IdleTimerWrapper.propTypes = {
  timeout: PropTypes.number.isRequired,
  onIdle: PropTypes.func.isRequired,
};

export default IdleTimerWrapper;
