import { useEffect, useState, useContext } from "react";
import { useAuthToken } from "./UseAuthToken";
import { UserContext } from "../context/UserContext";
import axios from "axios";

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const useUserData = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = useAuthToken();

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        setIsLoading(true);
        try {
          const response = await axios.get(`${SERVER_BASE_URL}/user`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUser(response.data);
        } catch (e) {
          setError(e);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [token]);
  return [user, isLoading, error];
};

const useBasicUserData = () => {
  const { basicUserData, setBasicUserData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(!basicUserData);
  const [error, setError] = useState(null);
  const token = useAuthToken();

  useEffect(() => {
    const fetchData = async () => {
      if (token && !basicUserData) {
        setIsLoading(true);
        try {
          const response = await axios.get(`${SERVER_BASE_URL}/user/basic`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setBasicUserData(response.data);
        } catch (e) {
          setError(e);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [token, basicUserData, setBasicUserData]);

  return [basicUserData, isLoading, error];
};

const useDeleteUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = useAuthToken();

  const deleteUser = async () => {
    if (token) {
      setIsLoading(true);
      await axios
        .delete(`${SERVER_BASE_URL}/user/delete`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((e) => {
          setError(e);
          setIsLoading(false);
        });
      setIsLoading(false);
    }
  };

  return { deleteUser, isLoading, error };
};

export { useUserData, useBasicUserData, useDeleteUser };
