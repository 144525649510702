import React from "react";
import PropTypes from "prop-types";
import { useDeleteUser } from "../../hooks/UserData";
import Button from "../../components/Button/Button";
import { GOOGLE_FORM_FOR_FEEDBACK } from "../../constants/constants";

const DeleteAccount = ({ onLogout }) => {
  const { deleteUser, isLoading, error } = useDeleteUser();

  const handleDeleteAccount = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete your account?"
    );
    if (confirmed) {
      await deleteUser();
      onLogout();
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <p style={{ color: "white" }}>
        Sad to see you go... Before you delete your account, we would appreciate
        any feedback you may have about our service. Please consider leaving us
        a message{" "}
        <a
          href={GOOGLE_FORM_FOR_FEEDBACK}
          rel="external nofollow noopener noreferrer"
          target="_blank"
          style={{ color: "#3f51b5", textDecoration: "none" }}
        >
          here
        </a>
      </p>
      <Button
        text={isLoading ? "Loading..." : "Delete Account"}
        onClick={handleDeleteAccount}
        disabled={isLoading}
        color="#ff0000"
      />
      {error && <p style={{ color: "white" }}>Error deleting account</p>}
    </div>
  );
};

DeleteAccount.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default DeleteAccount;
