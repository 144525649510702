import ReactGA from "react-ga4";
import { MEASUREMENT_ID } from "../constants/constants";

ReactGA.initialize(MEASUREMENT_ID);

export const sendAnalytics = (path) => {
  ReactGA.send({
    hitType: "pageview",
    page: path,
  });
};
