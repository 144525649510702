import React, { createContext, useState, useCallback } from "react";
import PropTypes from "prop-types";

export const RoomContext = createContext();

export const RoomProvider = ({ children }) => {
  const [room, setRoom] = useState(null);
  const [roomName, setRoomName] = useState(null);
  const [interestIndicated, setInterestIndicated] = useState(false);
  const [mutualInterest, setMutualInterest] = useState(false);
  const [partnerName, setPartnerName] = useState(null);
  const [alreadyFriends, setAlreadyFriends] = useState(false);

  const updateRoom = useCallback((newRoom) => {
    setRoom(newRoom);
    setInterestIndicated(false);
    setMutualInterest(false);
  }, []);

  const updateRoomName = useCallback((newRoomName) => {
    setRoomName(newRoomName);
    setInterestIndicated(false);
    setMutualInterest(false);
  }, []);

  const updateInterestIndicated = useCallback((val) => {
    setInterestIndicated(val);
  }, []);

  const updateMutualInterest = useCallback((val) => {
    setMutualInterest(val);
  }, []);

  const updatePartnerName = useCallback((newPartnerName) => {
    setPartnerName(newPartnerName);
  }, []);

  const updateAlreadyFriends = useCallback((alreadyFriends) => {
    setAlreadyFriends(alreadyFriends);
  }, []);

  return (
    <RoomContext.Provider
      value={{
        room,
        updateRoom,
        roomName,
        updateRoomName,
        interestIndicated,
        mutualInterest,
        updateInterestIndicated,
        updateMutualInterest,
        partnerName,
        updatePartnerName,
        alreadyFriends,
        updateAlreadyFriends,
      }}
    >
      {children}
    </RoomContext.Provider>
  );
};

RoomProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
