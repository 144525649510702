import React from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ChatBox from "./ChatBox";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Drawer from "@mui/material/Drawer";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  backgroundColor: "grey",
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const ChatBoxWrapper = ({
  open,
  handleDrawerClose,
  roomName,
  authorId,
  onNewMessage,
  drawerWidth,
  drawerShouldBeginAt,
}) => {
  const theme = useTheme();
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const drawerHeight = isVerySmallScreen
    ? "60vh"
    : isSmallScreen
    ? "70vh"
    : isMediumScreen
    ? "70vh"
    : "70vh";

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          height: "100%",
          backgroundColor: "#212529",
          flexShrink: 0,
          top: drawerShouldBeginAt,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            borderRadius: "10px",
            marginTop: drawerShouldBeginAt,
            marginRight: "2%",
            height: drawerHeight,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <ChatBox
          roomName={roomName}
          authorId={authorId}
          onNewMessage={onNewMessage}
        />
      </Drawer>
    </>
  );
};

ChatBoxWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  roomName: PropTypes.string.isRequired,
  authorId: PropTypes.string.isRequired,
  onNewMessage: PropTypes.func.isRequired,
  drawerWidth: PropTypes.number.isRequired,
  drawerShouldBeginAt: PropTypes.string.isRequired,
};

export default ChatBoxWrapper;
