import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [basicUserData, setBasicUserData] = useState(null);

  return (
    <UserContext.Provider
      value={{
        basicUserData,
        setBasicUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
