import React from "react";
import PropTypes from "prop-types";
import CSS from "./Message.module.css";

const Message = ({ sender, author, body }) => {
  const messageClass = author === sender ? CSS.userMessage : CSS.partnerMessage;

  return (
    <div className={messageClass}>
      <div className={CSS.messageContainer}>
        <span>{body}</span>
      </div>
    </div>
  );
};

Message.propTypes = {
  sender: PropTypes.string,
  author: PropTypes.string,
  body: PropTypes.string,
};

export default Message;
