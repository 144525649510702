import React from "react";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../Button/Button";

const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

const LoginButton = ({ provider }) => {
  const { loginWithRedirect } = useAuth0();

  const providerToConnection = {
    google: "google-oauth2",
    microsoft: "vibeu-microsoft-login",
  };

  const connection = providerToConnection[provider];

  const handleLogin = () => {
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}/post-login`,
        audience: AUTH0_AUDIENCE,
        connection: connection,
      },
    });
  };

  return <Button onClick={handleLogin} text="Login" size="large" />;
};

LoginButton.propTypes = {
  provider: PropTypes.string.isRequired,
};

export default LoginButton;
