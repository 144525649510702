import { useState, useEffect } from "react";
import { useAuthToken } from "./UseAuthToken";
import axios from "axios";

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const useLeaderboardData = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [leaderboardData, setLeaderBoardData] = useState([]);

  const token = useAuthToken();

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        setIsLoading(true);
        try {
          const response = await axios.get(`${SERVER_BASE_URL}/leaderboard`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setLeaderBoardData(response.data);
        } catch (e) {
          setError(e);
        }
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  return [leaderboardData, isLoading, error];
};

export { useLeaderboardData };
