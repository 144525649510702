import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const useAuthToken = () => {
  const [token, setToken] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      } catch (error) {
        setToken(null);
      }
    };

    fetchToken();
  }, [getAccessTokenSilently]);

  return token;
};
