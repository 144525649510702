import React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import CSS from "./ChatBox.module.css";
import Message from "./Message";
import SendIcon from "@mui/icons-material/Send";
import { SocketContext } from "../../context/SocketContext";
import { MAX_MESSAGES } from "../../constants/constants";

const ChatBox = ({ roomName, authorId, onNewMessage }) => {
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }

    socket.emit("joinRoom", { roomName });

    socket.on("newMessage", (msg) => {
      setMessages((prev) => {
        if (prev.length >= MAX_MESSAGES) {
          return [...prev.slice(1), msg];
        }
        return [...prev, msg];
      });
      if (onNewMessage) {
        onNewMessage(msg);
      }
    });

    return () => {
      socket.off("newMessage");
      socket.emit("leaveRoom", { roomName });
    };
  }, [socket, setMessages, roomName, onNewMessage]);

  const sendMessage = (message) => {
    if (message.trim()) {
      if (!socket.connected) {
        socket.connect();
      }

      const newMessage = {
        body: message,
        author: authorId,
      };

      socket.emit("sendMessage", {
        roomName,
        message: newMessage,
      });
    }
  };

  useEffect(() => {
    let observer;
    if (messagesEndRef.current) {
      const callback = (mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList") {
            messagesEndRef.current.scroll({
              top: messagesEndRef.current.scrollHeight,
              behavior: "smooth",
            });
          }
        }
      };

      observer = new MutationObserver(callback);
      observer.observe(messagesEndRef.current, { childList: true });
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  const handleTextareaInput = (e) => {
    const textarea = e.target;
    textarea.style.height = "auto";
    const maxHeight = parseInt(window.getComputedStyle(textarea).maxHeight);
    textarea.style.height = "";
    const height = textarea.scrollHeight;
    textarea.style.height = `${Math.min(height, maxHeight)}px`;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      const messageBody = e.target.value.trim();
      if (messageBody) {
        sendMessage(messageBody);
        e.target.value = "";
        e.target.style.height = "auto";
      }
    }
  };

  const handleMessageSend = (e) => {
    e.preventDefault();
    const messageBody = e.target.elements.messageBody.value.trim();
    if (messageBody) {
      sendMessage(messageBody);
      e.target.elements.messageBody.value = "";
      e.target.elements.messageBody.style.height = "auto";
    }
  };

  return (
    <div className={CSS.chatBox}>
      <p className={CSS.chatDisclaimer}>
        Messages can only be seen by matched users and are deleted when
        disconnected. Only the last {MAX_MESSAGES} messages are displayed.
      </p>
      <div className={CSS.messages} ref={messagesEndRef}>
        {messages.map((message, index) => (
          <Message
            key={`msg-${index}`}
            sender={authorId}
            author={message.author}
            body={message.body}
          />
        ))}
      </div>
      <form className={CSS.messageInputContainer} onSubmit={handleMessageSend}>
        <textarea
          name="messageBody"
          placeholder="Type a message..."
          rows="1"
          onInput={handleTextareaInput}
          onKeyDown={handleKeyDown}
        ></textarea>

        <button className={CSS.sendButton} type="submit">
          <SendIcon style={{ color: "#3f51b5" }} />
        </button>
      </form>
    </div>
  );
};

ChatBox.propTypes = {
  roomName: PropTypes.string.isRequired,
  authorId: PropTypes.string.isRequired,
  onNewMessage: PropTypes.func.isRequired,
};

export default ChatBox;
