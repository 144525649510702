import React, { useEffect, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Video from "twilio-video";
import { RoomContext } from "../../context/RoomContext";
import { MediaContext } from "../../context/MediaContext";
import Participant from "../RoomParticipant/Participant.jsx";
import VideoGrid from "../VideoGrid/VideoGrid";
import Box from "@mui/material/Box";
import "./VideoStyles.module.css";

const Room = ({ roomName, roomToken, deviceType }) => {
  const {
    room,
    updateRoom,
    updateRoomName,
    updatePartnerName,
    updateAlreadyFriends,
  } = useContext(RoomContext);
  const { audioEnabled, videoEnabled, mediaStream } = useContext(MediaContext);

  const [remoteVideoEnabled, setRemoteVideoEnabled] = useState(true);
  const [remoteAudioEnabled, setRemoteAudioEnabled] = useState(true);

  useEffect(() => {
    if (room) {
      const handleVideoDisabled = (track) => {
        if (track.kind === "video") {
          setRemoteVideoEnabled(false);
        }
      };

      const handleVideoEnabled = (track) => {
        if (track.kind === "video") {
          setRemoteVideoEnabled(true);
        }
      };

      const handleAudioDisabled = (track) => {
        if (track.kind === "audio") {
          setRemoteAudioEnabled(false);
        }
      };

      const handleAudioEnabled = (track) => {
        if (track.kind === "audio") {
          setRemoteAudioEnabled(true);
        }
      };

      const handleTrackSubscribed = (track) => {
        if (track.kind === "video") {
          track.on("disabled", handleVideoDisabled);
          track.on("enabled", handleVideoEnabled);
          if (track.isEnabled) {
            handleVideoEnabled(track);
          } else {
            handleVideoDisabled(track);
          }
        }

        if (track.kind === "audio") {
          track.on("disabled", handleAudioDisabled);
          track.on("enabled", handleAudioEnabled);
          if (track.isEnabled) {
            handleAudioEnabled(track);
          } else {
            handleAudioDisabled(track);
          }
        }
      };

      const handleTrackUnsubscribed = (track) => {
        if (track.kind === "video") {
          track.off("disabled", handleVideoDisabled);
          track.off("enabled", handleVideoEnabled);
        }

        if (track.kind === "audio") {
          track.off("disabled", handleAudioDisabled);
          track.off("enabled", handleAudioEnabled);
        }
      };

      const handleParticipantConnected = (participant) => {
        participant.tracks.forEach((publication) => {
          if (publication.isSubscribed) {
            handleTrackSubscribed(publication.track);
          }
        });
        participant.on("trackSubscribed", handleTrackSubscribed);
        participant.on("trackUnsubscribed", handleTrackUnsubscribed);
      };

      // Add event listeners for existing remote participants
      room.participants.forEach(handleParticipantConnected);

      // When a new participant connects, add event listeners for them
      room.on("participantConnected", handleParticipantConnected);

      return () => {
        room.participants.forEach((participant) => {
          participant.off("trackSubscribed", handleTrackSubscribed);
          participant.off("trackUnsubscribed", handleTrackUnsubscribed);
        });
        room.off("participantConnected", handleParticipantConnected);
      };
    }
  }, [room]);

  useEffect(() => {
    if (room) {
      if (audioEnabled === false) {
        room.localParticipant.audioTracks.forEach((trackPublication) => {
          trackPublication.track.disable();
        });
      } else if (audioEnabled === true) {
        room.localParticipant.audioTracks.forEach((trackPublication) => {
          trackPublication.track.enable();
        });
      }
    }
  }, [audioEnabled, room]);

  useEffect(() => {
    if (room) {
      if (videoEnabled === false) {
        room.localParticipant.videoTracks.forEach((trackPublication) => {
          trackPublication.track.disable();
        });
      } else if (videoEnabled === true) {
        room.localParticipant.videoTracks.forEach((trackPublication) => {
          trackPublication.track.enable();
        });
      }
    }
  }, [videoEnabled, room]);

  const videoConstraints = useMemo(
    () => ({
      aspectRatio: deviceType === "Mobile" ? 4 / 3 : null,
    }),
    [deviceType]
  );

  useEffect(() => {
    let currentRoom = null;
    Video.connect(roomToken, {
      name: roomName,
      video: videoConstraints,
      audio: true,
    }).then((room) => {
      currentRoom = room;
      updateRoom(room);
      updateRoomName(roomName);
    });

    return () => {
      if (currentRoom && currentRoom.localParticipant.state === "connected") {
        currentRoom.localParticipant.tracks.forEach(function (
          trackPublication
        ) {
          trackPublication.track.stop();
        });
        currentRoom.disconnect();
        if (typeof currentRoom.destroy === "function") {
          currentRoom.destroy();
        }
        updateRoom(null);
        updateRoomName(null);
        updatePartnerName(null);
        updateAlreadyFriends(false);
      }
    };
  }, [
    roomName,
    roomToken,
    updateRoom,
    updateRoomName,
    deviceType,
    videoConstraints,
    mediaStream,
    updatePartnerName,
    updateAlreadyFriends,
  ]);

  useEffect(() => {
    if (room !== null) {
      window.addEventListener("beforeunload", () => {
        room.disconnect();
      });
    }
  }, [room]);

  const remoteParticipant = room
    ? Array.from(room.participants.values())[0]
    : null;

  return (
    room &&
    remoteParticipant && (
      <VideoGrid>
        <Participant
          key={room.localParticipant.sid}
          participant={room.localParticipant}
          type="local"
          remoteAudioEnabled={remoteAudioEnabled}
        />
        {remoteVideoEnabled ? (
          <Participant
            key={remoteParticipant.sid}
            participant={remoteParticipant}
            type="remote"
            remoteAudioEnabled={remoteAudioEnabled}
          />
        ) : (
          <Box
            position="relative"
            width="100%"
            height="100%"
            maxWidth="100%"
            maxHeight="100%"
            overflow="hidden"
          >
            <Participant
              key={remoteParticipant.sid}
              participant={remoteParticipant}
              type="remote"
              remoteAudioEnabled={remoteAudioEnabled}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1,
              }}
            ></Box>
          </Box>
        )}
      </VideoGrid>
    )
  );
};

Room.propTypes = {
  roomName: PropTypes.string.isRequired,
  roomToken: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
};

export default Room;
