import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Login from "./views/Login/Login";
import { useAuth0 } from "@auth0/auth0-react";
import NavBar from "./components/NavBar/NavBar";
import Dashboard from "./views/Dashboard/Dashboard";
import Leaderboard from "./views/Leaderboard/Leaderboard";
import Profile from "./views/Profile/Profile";
import About from "./views/About/About";
import Loading from "./components/Loading/Loading";
import PostLogin from "./views/Login/PostLogin";
import { SocketContext } from "./context/SocketContext";
import { RoomProvider } from "./context/RoomContext";
import { UserProvider } from "./context/UserContext";
import { MediaProvider } from "./context/MediaContext";
import IdleTimerWrapper from "./utils/IdleTimer";
import useSocket from "./hooks/useSocket";
import { sendAnalytics } from "./services/analyticsService";
import LandingPage from "./views/LandingPage/LandingPage";
import AuthenticatedRedirect from "./utils/AuthenticatedRedirect";
import Shop from "./views/Shop/Shop";
import UserPosts from "./views/Shop/UserPosts/UserPosts";

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

function usePageViews() {
  let location = useLocation();

  useEffect(() => {
    sendAnalytics(location.pathname + location.search);
  }, [location]);
}

const loadingSpinner = (
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <Loading />
  </Box>
);

const ProtectedRoute = ({
  component: Component,
  isLoginSuccessful,
  isSocketConnected,
  ...rest
}) => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated || isLoginSuccessful === false) {
    return <Navigate to="/login" replace />;
  }

  if (!isSocketConnected) {
    return loadingSpinner;
  }

  return <Component {...rest} />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  isLoginSuccessful: PropTypes.bool,
  isSocketConnected: PropTypes.bool,

  rest: PropTypes.object,
};

function App() {
  const { isAuthenticated, isLoading, logout, error } = useAuth0();
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(null);
  const { socket, isSocketConnected, socketError } = useSocket(SERVER_BASE_URL);
  const location = useLocation();

  usePageViews();

  useEffect(() => {
    // Check if we've navigated to a specific route where we want to disconnect the socket
    if (location.pathname !== "/dashboard") {
      if (socket.current) {
        socket.current.emit("cleanup");
      }
    }
  }, [location, socket]);

  useEffect(() => {
    const handleUnload = () => {
      if (socket.current) {
        socket.current.emit("cleanup");
      }
    };
    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
      handleUnload();
    };
  }, [socket]);

  const handleLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    if (socket.current) {
      socket.current.emit("cleanup");
      socket.current.disconnect();
    }
  }, [socket, logout]);

  if (isLoading) {
    return loadingSpinner;
  }

  if (error) {
    const DELAY_SECONDS = 3;
    setTimeout(() => {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }, DELAY_SECONDS * 1000);

    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography variant="body1" color="white">
          An error has occurred...Redirecting to login.
        </Typography>
      </Box>
    );
  }

  const showNavBar =
    isAuthenticated &&
    location.pathname !== "/login" &&
    location.pathname !== "/post-login" &&
    location.pathname !== "/";

  if (!isSocketConnected) {
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      {socketError ? (
        <>
          <p>Error connecting to server: {socketError}</p>
          <button onClick={() => window.location.reload()}>Retry</button>
        </>
      ) : (
        loadingSpinner
      )}
    </Box>;
  }

  return (
    <div className="App">
      <UserProvider>
        <MediaProvider>
          <RoomProvider>
            <SocketContext.Provider
              value={{ socket: socket.current, isConnected: isSocketConnected }}
            >
              {showNavBar && <NavBar onLogout={handleLogout} />}
              <Box
                sx={{
                  marginTop: showNavBar ? "64px" : "0px",
                  marginBottom: "24px",
                  overflow: "hidden",
                }}
              >
                <Routes>
                  <Route
                    path="/login"
                    element={
                      <AuthenticatedRedirect>
                        <Login />
                      </AuthenticatedRedirect>
                    }
                  />
                  <Route
                    path="/post-login"
                    element={
                      <PostLogin setIsLoginSuccessful={setIsLoginSuccessful} />
                    }
                  />
                  <Route
                    path="/"
                    element={
                      isAuthenticated &&
                      isLoginSuccessful &&
                      isSocketConnected ? (
                        <Navigate to="/dashboard" />
                      ) : (
                        <AuthenticatedRedirect>
                          <LandingPage />
                        </AuthenticatedRedirect>
                      )
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedRoute
                        component={Dashboard}
                        isLoginSuccessful={isLoginSuccessful}
                        isSocketConnected={isSocketConnected}
                      />
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <ProtectedRoute
                        component={Profile}
                        isLoginSuccessful={isLoginSuccessful}
                        onLogout={handleLogout}
                        isSocketConnected={isSocketConnected}
                      />
                    }
                  />
                  <Route
                    path="/about"
                    element={
                      <ProtectedRoute
                        component={About}
                        isLoginSuccessful={isLoginSuccessful}
                        isSocketConnected={isSocketConnected}
                      />
                    }
                  />
                  <Route
                    path="/shop"
                    element={
                      <ProtectedRoute
                        component={Shop}
                        isLoginSuccessful={isLoginSuccessful}
                        isSocketConnected={isSocketConnected}
                      />
                    }
                  />
                  <Route
                    path="/leaderboard"
                    element={
                      <ProtectedRoute
                        component={Leaderboard}
                        isLoginSuccessful={isLoginSuccessful}
                        isSocketConnected={isSocketConnected}
                      />
                    }
                  />
                  <Route
                    path="/user-posts"
                    element={
                      <ProtectedRoute
                        component={UserPosts}
                        isLoginSuccessful={isLoginSuccessful}
                        isSocketConnected={isSocketConnected}
                      />
                    }
                  />
                </Routes>
              </Box>
              <IdleTimerWrapper
                //timeout at 60 minutes
                timeout={180 * 60 * 1000}
                onIdle={handleLogout}
              />
            </SocketContext.Provider>
          </RoomProvider>
        </MediaProvider>
      </UserProvider>
    </div>
  );
}

export default App;
