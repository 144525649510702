export const APP_NAME = "VibeU";
export const MAX_MESSAGES = 100;
export const WEBSITE_PUBLIC_URL = "https://www.vibeu.io";
export const GOOGLE_FORM_FOR_FEEDBACK = "https://forms.gle/ZhmMLZTCdcLXbNHh6";
export const CONTACT_EMAIL = "admin@vibeu.io";
export const JOIN_OUR_WAITLIST = "https://forms.gle/v3N5NNRxHk5MZCqL6";
export const MEASUREMENT_ID = "G-3ZTTSREPLY";
export const PRIVACY_POLICY =
  "https://vibeuio.notion.site/vibeuio/Privacy-Policy-for-Vibeu-io-78aa17b8a5444e4da30e6532cddc77d8";
export const NOTION_WHY_WE_STARTED =
  "https://vibeuio.notion.site/Why-we-started-176a5737b3204876bb2235799940753d?pvs=4";
export const NOTION_TERMS_OF_SERVICE =
  "https://vibeuio.notion.site/vibeuio/Terms-of-Service-9c7116e279734e5e9bdd29ef8e8b0e55";
export const HORNET_TRIBUNE_FEATURE =
  "https://asuhornettribune.com/12912/university-news/12912/";
