import { Box } from "@mui/material";
import { keyframes } from "@emotion/react";
import React from "react";

const shimmerAnimation = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
`;

const ShimmerEffect = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100%",
        "@media (min-width: 600px)": {
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "80%",
          minHeight: "100%",
          borderRadius: "5px",
          backgroundColor: "#2c3034",
          overflow: "hidden",
          "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background:
              "linear-gradient(to right, #2c3034 0%, #383d41 50%, #2c3034 100%)",
            transform: "skewX(-20deg)",
            animation: `${shimmerAnimation} 2s infinite linear`,
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
          gap: "10px",
          width: "100%", // Ensure full width to center icons
        }}
      >
        {[...Array(5)].map((_, index) => (
          <Box
            key={index}
            sx={{
              position: "relative",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              backgroundColor: "#2c3034",
              overflow: "hidden",
              "&::after": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(to right, transparent 0%, #383d41 50%, transparent 100%)",
                transform: "skewX(-20deg)",
                animation: `${shimmerAnimation} 1.5s infinite linear`,
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ShimmerEffect;
