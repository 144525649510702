import React, { useEffect, useContext } from "react";
import Room from "../Room/Room.jsx";
import Loading from "../Loading/Loading.jsx";
import CSS from "./Lobby.module.css";
import UserVideo from "../UserVideo/UserVideo.jsx";
import VideoGrid from "../VideoGrid/VideoGrid.jsx";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { RoomContext } from "../../context/RoomContext.jsx";
import { useConnectToRoom } from "../../hooks/ConnectToRoom.js";

const Lobby = ({ deviceType }) => {
  const [
    isMatched,
    roomName,
    roomToken,
    message,
    isLoading,
    error,
    partnerDeviceType, // eslint-disable-line no-unused-vars
  ] = useConnectToRoom(deviceType);

  const { updateRoomName } = useContext(RoomContext);

  const loadingSpinner = (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Loading />
      <p className={CSS.loadingText}>{message || "Connecting..."}</p>
    </Box>
  );

  useEffect(() => {
    if (roomName && isMatched) {
      updateRoomName(roomName);
    }
  }, [roomName, isMatched, updateRoomName]);

  const renderContent = () => {
    if (error) {
      return (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography variant="body1" color="white">
            An error occurred
          </Typography>
        </Box>
      );
    }

    if (isLoading) {
      return (
        <VideoGrid>
          <UserVideo />
          <Box
            position="relative"
            width="100%"
            height="100%"
            maxWidth="100%"
            maxHeight="100%"
            overflow="hidden"
          >
            <UserVideo />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#212529",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1,
              }}
            >
              {loadingSpinner}
            </Box>
          </Box>
        </VideoGrid>
      );
    }

    if (roomName && isMatched) {
      return (
        <Room
          roomName={roomName}
          roomToken={roomToken}
          deviceType={deviceType}
        />
      );
    }

    return <UserVideo />;
  };

  return renderContent();
};

export default Lobby;
