import React from "react";
import PropTypes from "prop-types";
import VideoCard from "../VideoCard/VideoCard";

const MediaCard = ({
  audioRef,
  videoRef,
  type,
  audioEnabled,
  setAudioEnabled,
  videoEnabled,
  setVideoEnabled,
  remoteAudioEnabled,
}) => {
  const muted = type === "local";

  return (
    <>
      <VideoCard
        ref={videoRef}
        autoPlay={true}
        playsInline
        type={type}
        muted={true}
        setAudioEnabled={setAudioEnabled}
        setVideoEnabled={setVideoEnabled}
        remoteAudioEnabled={remoteAudioEnabled}
        audioEnabled={audioEnabled}
        videoEnabled={videoEnabled}
      />
      <audio ref={audioRef} autoPlay={true} muted={muted} />{" "}
    </>
  );
};

MediaCard.propTypes = {
  audioRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  videoRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  audioEnabled: PropTypes.bool.isRequired,
  videoEnabled: PropTypes.bool.isRequired,
  setAudioEnabled: PropTypes.func.isRequired,
  setVideoEnabled: PropTypes.func.isRequired,
  remoteAudioEnabled: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

export default MediaCard;
