import React from "react";
import { Box, Typography } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useLeaderboardData } from "../../hooks/UseLeaderboardData";
import Loading from "../../components/Loading/Loading";

const textColor = "#D3D3D3";
const backgroundColor = "#212529";
const currentUserTextColor = "#3f51b5";

const getIconColor = (position) => {
  switch (position) {
    case 1:
      return "gold";
    case 2:
      return "#C0C0C0";
    case 3:
      return "#CD7F32";
    default:
      return null;
  }
};

const loadingSpinner = (
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <Loading />
  </Box>
);

const Leaderboard = () => {
  const [leaderboardData, isLeaderBoardDataLoading, isLeaderBoardDataError] =
    useLeaderboardData();

  if (isLeaderBoardDataLoading) {
    return loadingSpinner;
  }

  if (isLeaderBoardDataError) {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography variant="body1" color="white">
          An error occurred.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        py: 6,
        px: { xs: 2, sm: 3, md: 4 },
        bgcolor: backgroundColor,
        color: textColor,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: "md",
        mx: "auto",
      }}
    >
      <Typography
        variant="h5"
        textAlign="center"
        gutterBottom
        sx={{ color: textColor }}
      >
        Leaderboard
      </Typography>
      {leaderboardData.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            py: 2,
            borderBottom: "1px solid",
            borderColor: "gray",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ mr: 1, fontWeight: "bold" }}>
              {item.position}
            </Typography>
            {item.position <= 3 && (
              <EmojiEventsIcon
                sx={{
                  color: getIconColor(item.position),
                  mr: 1,
                  fontSize: "1.5rem",
                }}
              />
            )}
            <Typography
              sx={{
                color: item.isCurrentUser ? currentUserTextColor : textColor,
              }}
            >
              {item.isCurrentUser ? "You" : item.name}
            </Typography>{" "}
          </Box>
          <Typography sx={{ fontWeight: "bold" }}>{item.score}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Leaderboard;
