import React, { useState, useEffect, useRef, useContext } from "react";
import { MediaContext } from "../../context/MediaContext";
import PropTypes from "prop-types";
import MediaCard from "../MediaCard/MediaCard";

const Participant = ({ participant, type, remoteAudioEnabled }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  const { audioEnabled, videoEnabled, setAudioEnabled, setVideoEnabled } =
    useContext(MediaContext);
  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <MediaCard
      audioRef={audioRef}
      videoRef={videoRef}
      audioEnabled={audioEnabled}
      videoEnabled={videoEnabled}
      setAudioEnabled={setAudioEnabled}
      setVideoEnabled={setVideoEnabled}
      remoteAudioEnabled={remoteAudioEnabled}
      type={type}
    />
  );
};

Participant.propTypes = {
  participant: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  remoteAudioEnabled: PropTypes.bool,
};

export default Participant;
