import { useContext } from "react";
import { SocketContext } from "../context/SocketContext.jsx";
import { useAuthToken } from "./UseAuthToken.js";
import axios from "axios";

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const useNextOrStopAction = (action) => {
  const { socket } = useContext(SocketContext);
  const token = useAuthToken();

  const executeAction = async (roomName) => {
    try {
      if (token) {
        if (!roomName && action === "stop") {
          axios.post(`${SERVER_BASE_URL}/pool/delete`, null, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        }

        if (!socket.connected) {
          socket.connect();
        }

        socket.emit(action, { roomName });
      }
    } catch (e) {
      () => {};
    }
  };

  return [executeAction];
};

const useInterestAction = () => {
  const { socket } = useContext(SocketContext);
  const token = useAuthToken();

  const indicateInterest = async (roomName) => {
    try {
      if (token) {
        if (!socket.connected) {
          socket.connect();
        }

        socket.emit("indicate_interest", { roomName });
      }
    } catch (e) {
      () => {};
    }
  };

  return [indicateInterest];
};

const useReportAction = ({ roomName }) => {
  const { socket } = useContext(SocketContext);
  const token = useAuthToken();

  const reportAction = async (reportText, reportType) => {
    try {
      if (token) {
        if (!socket.connected) {
          socket.connect();
        }
        let now = new Date();

        let timestamp =
          now.toLocaleDateString() + " " + now.toLocaleTimeString();

        socket.emit("reportAction", {
          roomName,
          reportObj: {
            reason: reportText,
            timestamp: timestamp,
            reportAction: reportType,
          },
        });
      }
    } catch (e) {
      () => {};
    }
  };

  return [reportAction];
};

export { useNextOrStopAction, useInterestAction, useReportAction };
