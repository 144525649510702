import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import Lobby from "../../components/Lobby/Lobby";
import Loading from "../../components/Loading/Loading";
import UserVideo from "../../components/UserVideo/UserVideo";
import Notification from "../../components/Notification/Notification";
import { RoomContext } from "../../context/RoomContext";
import { MediaContext } from "../../context/MediaContext";
import { SocketContext } from "../../context/SocketContext.jsx";
import { useBasicUserData } from "../../hooks/UserData";
import { useNextOrStopAction, useInterestAction } from "../../hooks/RoomAction";
import useIsMounted from "../../hooks/IsMounted";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ChatBoxWrapper from "../../components/ChatBox/ChatBoxWrapper";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import FastForwardIcon from "@mui/icons-material/FastForward";
import StopIcon from "@mui/icons-material/Stop";
import ChatIcon from "@mui/icons-material/Chat";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import IconButton from "../../components/IconButton/IconButton";
import { isBrowser, isMobile, isTablet } from "react-device-detect";
import CSS from "./Dashboard.module.css";

const getDeviceType = () => {
  if (isMobile) return "Mobile";
  if (isTablet) return "Tablet";
  if (isBrowser) return "Desktop";
  return "unknown";
};

const loadingSpinner = (
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <Loading />
  </Box>
);

const Main = styled("main", {
  shouldForwardProp: (prop) =>
    prop !== "roomName" &&
    prop !== "open" &&
    prop !== "drawerWidth" &&
    prop !== "drawerShouldOverlay",
})(({ theme, open, roomName, drawerWidth, drawerShouldOverlay }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: roomName ? -drawerWidth : 0,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerShouldOverlay ? -drawerWidth : 0,
  }),
}));

const Dashboard = () => {
  const [userData, isLoading, error] = useBasicUserData();
  const [showLobby, setShowLobby] = useState(false);
  const [lobbyKey, setLobbyKey] = useState(0);
  const [interestNotification, setInterestNotification] = useState({});
  const [mutualNotification, setMutualNotification] = useState({});
  const [deviceType, setDeviceType] = useState(getDeviceType());
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [deviceOrientationNotification, setDeviceOrientationNotification] =
    useState({});
  const [isPortrait, setIsPortrait] = useState(false);
  const [alreadyFriendsNotification, setAlreadyFriendsNotification] = useState(
    {}
  );

  const audioPlayer = useRef();

  const isMounted = useIsMounted();

  const {
    room,
    roomName,
    interestIndicated,
    mutualInterest,
    updateInterestIndicated,
    updateMutualInterest,
    partnerName,
    updatePartnerName,
    alreadyFriends,
    updateAlreadyFriends,
  } = useContext(RoomContext);
  const { socket } = useContext(SocketContext);
  const { mediaError } = useContext(MediaContext);

  const [next] = useNextOrStopAction("next");
  const [stop] = useNextOrStopAction("stop");
  const [indicateInterest] = useInterestAction();

  const [open, setOpen] = useState(false);

  const textColor = "#D3D3D3";
  const theme = useTheme();
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const drawerWidth =
    isSmallScreen || isVerySmallScreen ? 300 : isMediumScreen ? 320 : 350;

  const videoGridRef = useRef(null);

  const [drawerStartPosition, setDrawerStartPosition] = useState("10%");
  const [showGlowEffect, setShowGlowEffect] = useState(false);
  const [showStars, setShowStars] = useState(false);

  useEffect(() => {
    setDeviceType(getDeviceType());
  }, [setDeviceType]);

  useEffect(() => {
    if (partnerName) {
      setShowGlowEffect(true);
      setShowStars(true);
      setTimeout(() => {
        setShowGlowEffect(false);
        setShowStars(false);
      }, 2000); // Duration of the effects
    }
  }, [partnerName]);

  useEffect(() => {
    if (alreadyFriends) {
      setAlreadyFriendsNotification({
        message: "Say hi to a friend!",
        type: "info",
      });
    } else {
      setAlreadyFriendsNotification({});
    }
  }, [alreadyFriends]);

  const updateDrawerStartPosition = () => {
    if (videoGridRef.current) {
      const rect = videoGridRef.current.getBoundingClientRect();
      const startPosition = rect.top;
      setDrawerStartPosition(`${startPosition}px`);
    }
  };

  useEffect(() => {
    if (open) {
      updateDrawerStartPosition();
    }
  }, [open]);

  useEffect(() => {
    return () => {
      if (roomName && !isMounted()) {
        next(roomName);
      }
    };
  }, [roomName, next, isMounted]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNewMessageNotif = () => {
    if (!open) {
      setHasUnreadMessages(true);
      if (audioPlayer.current) {
        audioPlayer.current.play();
      }
    }
  };

  const NotificationIconWrapper = styled("div")({
    position: "relative",
    display: "flex",
    justifyContent: "center",
  });

  const RedDot = styled("div")({
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: "red",
    position: "absolute",
    top: "0",
    right: "0",
    transform: "translate(50%, -50%)",
  });

  const checkOrientation = useCallback(() => {
    const isPortraitMode = window.innerHeight > window.innerWidth;
    setIsPortrait(isMobile && isPortraitMode);
  }, []);

  useEffect(() => {
    checkOrientation();
    window.addEventListener("resize", checkOrientation);
    if (isPortrait) {
      setDeviceOrientationNotification({
        message: "For a better chat experience please rotate mobile device",
        type: "info",
      });
    } else {
      setDeviceOrientationNotification({});
    }
    return () => window.removeEventListener("resize", checkOrientation);
  }, [checkOrientation, isPortrait]);

  useEffect(() => {
    setOpen(false);
  }, [roomName, setOpen]);

  useEffect(() => {
    if (open) {
      setHasUnreadMessages(false);
    }
  }, [open]);

  useEffect(() => {
    if (hasUnreadMessages && audioPlayer.current) {
      audioPlayer.current.play();
    }
  }, [hasUnreadMessages]);

  const handleStart = useCallback(async () => {
    setShowLobby(true);
  }, []);

  const handleNext = useCallback(async () => {
    setShowLobby((prevState) => {
      if (!prevState) {
        return true;
      }
      setLobbyKey((prevKey) => prevKey + 1);
      return true;
    });

    if (roomName) {
      next(roomName);
      updatePartnerName(null);
      updateAlreadyFriends(false);
    }
  }, [roomName, next, updatePartnerName, updateAlreadyFriends]);

  const handleStop = useCallback(async () => {
    setShowLobby(false);

    stop(roomName);
    updatePartnerName(null);
    updateAlreadyFriends(false);
  }, [roomName, stop, updatePartnerName, updateAlreadyFriends]);

  // remove message from here
  const handleInterested = useCallback(async () => {
    if (room && !interestIndicated) {
      indicateInterest(roomName);
      updateInterestIndicated(true);
    }
  }, [
    roomName,
    room,
    indicateInterest,
    updateInterestIndicated,
    interestIndicated,
  ]);

  useEffect(() => {
    setInterestNotification({});
    setMutualNotification({});
  }, [room]);

  const handleNextOrStopFromSocket = useCallback(() => {
    setLobbyKey((prevKey) => prevKey + 1);
    setInterestNotification({});
    setMutualNotification({});
    updatePartnerName(null);
    updateAlreadyFriends(false);
  }, [updatePartnerName, updateAlreadyFriends]);

  const handleMutualInterest = useCallback(() => {
    if (!mutualInterest && interestIndicated) {
      setMutualNotification({
        message: "Mutual interest confirmed!",
        type: "success",
      });
      updateMutualInterest(true);
    }
  }, [updateMutualInterest, mutualInterest, interestIndicated]);

  const handleNextOrStopFromSocketRef = useRef(handleNextOrStopFromSocket);

  useEffect(() => {
    handleNextOrStopFromSocketRef.current = handleNextOrStopFromSocket;
  }, [handleNextOrStopFromSocket]);

  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }

    function handleNext() {
      handleNextOrStopFromSocketRef.current();
    }

    function handleStop() {
      handleNextOrStopFromSocketRef.current();
    }

    function handleInterestIndicated() {
      setInterestNotification({
        message: "Your chat partner liked you!",
        type: "info",
      });
    }

    socket.on("next", handleNext);
    socket.on("stop", handleStop);
    socket.on("mutual_interest", handleMutualInterest);
    socket.on("interest_indicated", handleInterestIndicated);

    return () => {
      socket.off("next", handleNext);
      socket.off("stop", handleStop);
      socket.off("mutual_interest", handleMutualInterest);
      socket.off("interest_indicated", handleInterestIndicated);
    };
  }, [socket, handleMutualInterest]);

  const handleChatClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Box sx={{ display: "flex", overflow: "hidden", height: "100vh" }}>
      <Main
        open={open}
        roomName={roomName}
        drawerWidth={drawerWidth}
        drawerShouldOverlay={isVerySmallScreen}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 1,
            flexGrow: 1,
          }}
        >
          {!error && isLoading && loadingSpinner}
          {!error && !isLoading && (
            <>
              <Box
                sx={{
                  height: "10%",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  mb: 2,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    textDecoration: "none",
                    color: textColor,
                    zIndex: 1,
                    paddingLeft: "10px",
                    paddingBottom: "5px",
                    paddingRight: "10px",
                    borderRadius: "10px",
                    backgroundColor: "#36454F",
                    fontFamily: "'Chalkboard SE', 'Comic Sans MS', cursive",
                  }}
                >
                  <span>
                    Hi {userData.firstName}
                    {partnerName && (
                      <>
                        <span
                          className={CSS.star}
                          style={{ top: "-10px", left: "-15px" }}
                        ></span>
                        <span
                          className={CSS.star}
                          style={{ top: "15px", right: "-15px" }}
                        ></span>
                        <span className={showGlowEffect ? CSS.glow : ""}>
                          {`, you matched with `}
                          <span>{partnerName}!</span>
                          {showStars &&
                            [...Array(10)].map((_, index) => (
                              <span key={index} className={CSS.star} />
                            ))}
                        </span>
                        <span
                          className={CSS.star}
                          style={{ bottom: "-10px", right: "-15px" }}
                        ></span>
                        <span
                          className={CSS.star}
                          style={{ bottom: "5px", left: "-15px" }}
                        ></span>
                      </>
                    )}
                  </span>
                </Typography>
              </Box>
              <Box
                ref={videoGridRef}
                sx={{
                  height: "80%",
                  maxHeight: "80vh",
                  overflow: "hidden",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {showLobby ? (
                  <Lobby key={lobbyKey} deviceType={deviceType} />
                ) : (
                  <UserVideo />
                )}
              </Box>
              <Box
                sx={{
                  height: "10%",
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gap: 1,
                  mt: 2,
                  p: 2,
                  zIndex: 1,
                }}
              >
                <IconButton
                  onClick={handleStart}
                  disabled={Boolean(showLobby || room || mediaError)}
                  tooltip="Match"
                >
                  <PlayArrowIcon />
                </IconButton>

                <IconButton
                  onClick={handleNext}
                  disabled={Boolean(!room)}
                  tooltip="Next"
                >
                  <FastForwardIcon />
                </IconButton>

                <IconButton
                  onClick={handleStop}
                  disabled={Boolean(!showLobby || mediaError)}
                  tooltip="Stop"
                >
                  <StopIcon />
                </IconButton>

                <IconButton
                  onClick={handleChatClick}
                  disabled={Boolean(!room || mediaError)}
                  tooltip="Chat with current match"
                >
                  <NotificationIconWrapper>
                    <ChatIcon style={{ fontSize: "22px" }} />
                    {!open && hasUnreadMessages && <RedDot />}
                  </NotificationIconWrapper>
                </IconButton>

                <IconButton
                  onClick={handleInterested}
                  disabled={Boolean(
                    !room || interestIndicated || mediaError || alreadyFriends
                  )}
                  tooltip="Indicate interest in current match"
                >
                  <ThumbUpIcon />
                </IconButton>
              </Box>
              {interestNotification.message && (
                <Notification
                  message={interestNotification.message}
                  type={interestNotification.type}
                />
              )}
              {mutualNotification.message && (
                <Notification
                  message={mutualNotification.message}
                  type={mutualNotification.type}
                />
              )}
              {isPortrait && deviceOrientationNotification.message && (
                <Notification
                  message={deviceOrientationNotification.message}
                  type={deviceOrientationNotification.type}
                />
              )}
              {alreadyFriendsNotification.message && (
                <Notification
                  message={alreadyFriendsNotification.message}
                  type={alreadyFriendsNotification.type}
                />
              )}
            </>
          )}
          {error && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Typography variant="body1" color="white">
                An error occurred
              </Typography>
            </Box>
          )}
          {mediaError && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Typography variant="body1" color="white">
                An error occurred while getting your media.
              </Typography>
            </Box>
          )}
        </Container>
      </Main>
      {roomName && (
        <ChatBoxWrapper
          open={open}
          handleDrawerClose={handleDrawerClose}
          drawerWidth={drawerWidth}
          roomName={roomName}
          authorId={userData.id}
          onNewMessage={handleNewMessageNotif}
          drawerShouldBeginAt={drawerStartPosition}
        />
      )}
    </Box>
  );
};

export default Dashboard;
