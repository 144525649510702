import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useAuthToken } from "./UseAuthToken";

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const useFetchShop = (url, updateFlag) => {
  const [shopData, setShopData] = useState([]);
  const [isShopLoading, setIsShopLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = useAuthToken();

  // Memoize headers
  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${token}`,
    }),
    [token]
  );

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      setIsShopLoading(true);
      try {
        const res = await axios.get(`${SERVER_BASE_URL}${url}`, { headers });
        if (!didCancel) {
          setShopData(res.data);
          setError(null);
        }
      } catch (err) {
        if (!didCancel) {
          setError(err);
        }
      } finally {
        if (!didCancel) {
          setIsShopLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true; // Abort the fetch operation if component unmounts
    };
  }, [url, updateFlag, headers]);

  return { shopData, isShopLoading, error };
};

export default useFetchShop;
