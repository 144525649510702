import React, { useState, createContext, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

export const MediaContext = createContext();

export const MediaProvider = ({ children }) => {
  const [mediaStream, setMediaStream] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [mediaError, setMediaError] = useState(null);

  const [audioEnabled, setAudioEnabled] = useState(true);
  const [videoEnabled, setVideoEnabled] = useState(true);

  const { isAuthenticated } = useAuth0();
  const location = useLocation();

  const allowMediaAccess =
    isAuthenticated && location.pathname === "/dashboard";

  const updateMediaTrack = useCallback((stream, type, enabled) => {
    const tracks =
      type === "audio" ? stream.getAudioTracks() : stream.getVideoTracks();
    tracks.forEach((track) => {
      track.enabled = enabled;
    });
  }, []);

  const setAudio = useCallback(
    (enabled) => {
      setAudioEnabled(enabled);
      if (mediaStream) {
        updateMediaTrack(mediaStream, "audio", enabled);
      }
    },
    [mediaStream, updateMediaTrack]
  );

  const setVideo = useCallback(
    (enabled) => {
      setVideoEnabled(enabled);
      if (mediaStream) {
        updateMediaTrack(mediaStream, "video", enabled);
      }
    },
    [mediaStream, updateMediaTrack]
  );

  const releaseMedia = useCallback(() => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null);
    }
  }, [mediaStream]);

  useEffect(() => {
    let isMounted = true;

    const getMedia = async () => {
      if (!allowMediaAccess || mediaStream) return;

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: videoEnabled,
          audio: audioEnabled,
        });
        if (isMounted) {
          setMediaStream(stream);
          setRetryCount(0);
        }
      } catch (error) {
        setMediaError(error);
        if (retryCount < 5) {
          setTimeout(() => setRetryCount((prevCount) => prevCount + 1), 5000);
        }
      }
    };

    getMedia();

    return () => {
      isMounted = false;
    };
  }, [
    retryCount,
    allowMediaAccess,
    releaseMedia,
    videoEnabled,
    audioEnabled,
    mediaStream,
  ]);

  useEffect(() => {
    // Reapply the video and audio settings when returning to the dashboard
    if (allowMediaAccess && mediaStream) {
      updateMediaTrack(mediaStream, "audio", audioEnabled);
      updateMediaTrack(mediaStream, "video", videoEnabled);
    }
  }, [
    allowMediaAccess,
    mediaStream,
    audioEnabled,
    videoEnabled,
    updateMediaTrack,
  ]);

  return (
    <MediaContext.Provider
      value={{
        mediaStream,
        releaseMedia,
        mediaError,
        audioEnabled,
        videoEnabled,
        setAudioEnabled: setAudio,
        setVideoEnabled: setVideo,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};

MediaProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
