import React, { useState } from "react";
import PropTypes from "prop-types";
import MUITabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const Tabs = ({ TabToValue }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <MUITabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          {Object.keys(TabToValue).map((tabName, index) => (
            <Tab key={index} label={tabName} {...a11yProps(index)} />
          ))}
        </MUITabs>
      </Box>
      {Object.values(TabToValue).map((tabValue, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tabValue}
        </TabPanel>
      ))}
    </Box>
  );
};

Tabs.propTypes = {
  TabToValue: PropTypes.object.isRequired,
};

export default Tabs;
