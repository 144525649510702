import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Loading from "../../components/Loading/Loading";
import Tabs from "../../components/Tabs/Tabs";
import FriendsGrid from "./Friends";
import DeleteAccount from "./DeleteAccount";
import MultiSectionForm from "../../components/MultiSectionForm/MultiSectionForm";
import CSS from "./Profile.module.css";
import { useUserData } from "../../hooks/UserData";
import { useFriendsData, useBlockedFriendsData } from "../../hooks/FriendData";
import useSaveProfileForm from "../../hooks/FormSave";
import { getSectionToFormFields } from "./utils";
import PersonIcon from "@mui/icons-material/Person";
import { Typography } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";

const loadingSpinner = (
  <div className={CSS.loading}>
    <Loading />
  </div>
);

const Profile = ({ onLogout }) => {
  const [user, isLoading, error] = useUserData();
  const [sectionToFormFields, setSectionToFormFields] = useState({});
  const [isProfileSaving, profileSavingError, onFormsSave, updatedProfile] =
    useSaveProfileForm(false);

  const [
    friendsData,
    friendsIsLoading,
    friendsError,
    setPage,
    page,
    totalPages,
    fetchFriends,
    blockFriend,
    removeFriend,
  ] = useFriendsData();

  const [
    blockedFriends,
    blockedFriendsIsLoading,
    blockedFriendsError,
    setBlockedFriendsPage,
    blockedFriendsPage,
    blockedFriendsTotalPages,
    fetchBlockedFriends,
    unblockFriend,
  ] = useBlockedFriendsData();
  const onBlockStatusChange = async (friendId, isBlockAction) => {
    if (isBlockAction === true) {
      await blockFriend(friendId);
    } else {
      await unblockFriend(friendId);
    }
    fetchFriends();
    fetchBlockedFriends();
  };

  const onRemoveFriendChange = async (friendId) => {
    await removeFriend(friendId);
    fetchFriends();
  };

  useEffect(() => {
    if (user) {
      setSectionToFormFields(getSectionToFormFields(user));
    }
  }, [user]);

  useEffect(() => {
    if (updatedProfile) {
      setSectionToFormFields(getSectionToFormFields(updatedProfile));
    }
  }, [updatedProfile]);

  const TabToValue = {
    "Profile Info": isProfileSaving ? (
      loadingSpinner
    ) : (
      <>
        {profileSavingError && (
          <div className={CSS.error}>
            <p style={{ color: "white" }}>
              An error occurred while saving the profile.
            </p>
          </div>
        )}
        <MultiSectionForm
          SectionToFormFields={sectionToFormFields}
          onFormsSave={onFormsSave}
        />
      </>
    ),
    Friends: friendsIsLoading ? (
      loadingSpinner
    ) : (
      <>
        {friendsError && (
          <div className={CSS.error}>
            <p style={{ color: "white" }}>
              An error occurred while saving the profile.
            </p>
          </div>
        )}

        <FriendsGrid
          friendsData={friendsData}
          setPage={setPage}
          page={page}
          totalPages={totalPages}
          onBlockStatusChange={onBlockStatusChange}
          onRemoveFriendChange={onRemoveFriendChange}
          type="unblocked"
        />
        {!totalPages ? (
          <div className={CSS.iconContainer}>
            <GroupIcon
              sx={{
                color: "white",
                height: "100px",
                width: "100px",
                position: "center",
              }}
            />
            <Typography sx={{ color: "white" }}>
              No friends yet. Don&#39;t worry keep chatting, someone is bound to
              come along 👍🏼
            </Typography>
          </div>
        ) : null}
      </>
    ),
    "Blocked Friends": blockedFriendsIsLoading ? (
      loadingSpinner
    ) : (
      <>
        {blockedFriendsError && (
          <div className={CSS.error}>
            <p style={{ color: "white" }}>
              An error occurred while fetching blocked friends.
            </p>
          </div>
        )}
        <FriendsGrid
          friendsData={blockedFriends}
          setPage={setBlockedFriendsPage}
          page={blockedFriendsPage}
          totalPages={blockedFriendsTotalPages}
          onBlockStatusChange={onBlockStatusChange}
          type="blocked"
        />
        {!blockedFriendsTotalPages ? (
          <div className={CSS.iconContainer}>
            <PersonIcon
              sx={{
                color: "white",
                height: "100px",
                width: "100px",
                position: "center",
              }}
            />
            <Typography sx={{ color: "white" }}>
              No accounts blocked...
            </Typography>
          </div>
        ) : null}
      </>
    ),
    "Account Settings": <DeleteAccount onLogout={onLogout} />,
  };

  return (
    <div className={CSS.profile}>
      {!error && isLoading && loadingSpinner}
      {!error && !isLoading && (
        <>
          <Tabs TabToValue={TabToValue} />
        </>
      )}
      {error && (
        <div className={CSS.error}>
          <p style={{ color: "white" }}> An error occurred </p>
        </div>
      )}
    </div>
  );
};

Profile.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default Profile;
