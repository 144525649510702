import { useState } from "react";
import axios from "axios";
import { useAuthToken } from "./UseAuthToken";

const convertFormData = (formData) => {
  const {
    Personal: { firstName, lastName },
    School: { schoolName, classification },
    Socials: {
      twitterUsername: twitter,
      snapchatUsername: snapchat,
      facebookUsername: facebook,
      instagramUsername: instagram,
      tiktokUsername: tiktok,
    },
  } = formData;

  return {
    firstName,
    lastName,
    socials: {
      twitter,
      facebook,
      snapchat,
      instagram,
      tiktok,
    },
    school: {
      classification,
      schoolName,
    },
  };
};

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const useSaveProfileForm = (initialIsSaving = false) => {
  const [isSaving, setIsSaving] = useState(initialIsSaving);
  const [updatedProfile, setUpdatedProfile] = useState(null);
  const [error, setError] = useState(null);

  const token = useAuthToken();

  const onFormsSave = async (formData) => {
    const finalFormData = convertFormData(formData);
    setIsSaving(true);

    try {
      const response = await axios.post(
        `${SERVER_BASE_URL}/user/profile/update`,
        {
          formData: finalFormData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Set the updated profile data
      setUpdatedProfile(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setIsSaving(false);
    }
  };

  return [isSaving, error, onFormsSave, updatedProfile];
};

export default useSaveProfileForm;
