import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { SocketContext } from "../context/SocketContext";
import { useAuthToken } from "./UseAuthToken";
import { RoomContext } from "../context/RoomContext";

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const useConnectToRoom = (deviceType) => {
  const [roomToken, setRoomToken] = useState(null);
  const [roomName, setRoomName] = useState(null);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isMatched, setIsMatched] = useState(false);
  const [partnerDeviceType, setPartnerDeviceType] = useState(null);

  const token = useAuthToken();

  const { socket } = useContext(SocketContext);
  const { updatePartnerName, updateAlreadyFriends } = useContext(RoomContext);

  useEffect(() => {
    const connectToRoom = async () => {
      setIsLoading(true);
      try {
        if (token) {
          if (!socket.connected) {
            socket.connect();
          }
          const response = await axios.post(
            `${SERVER_BASE_URL}/match`,
            {
              socketId: socket.id,
              deviceType: deviceType,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setMessage(response.data.message);
        }
      } catch (e) {
        setError(e);
        setIsLoading(false);
        return;
      }

      socket.on("matched", (data) => {
        setMessage("Matched...");
        setRoomToken(data.token);
        setRoomName(data.roomName);
        setPartnerDeviceType(data.deviceType);
        setIsMatched(true);
        setIsLoading(false);
        updatePartnerName(data.userName);
        updateAlreadyFriends(data.alreadyFriends);
      });
    };

    connectToRoom();

    return () => {
      socket.off("matched");
    };
  }, [socket, deviceType, token, updatePartnerName, updateAlreadyFriends]);

  return [
    isMatched,
    roomName,
    roomToken,
    message,
    isLoading,
    error,
    partnerDeviceType,
  ];
};

export { useConnectToRoom };
