import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const AuthenticatedRedirect = ({ children }) => {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

AuthenticatedRedirect.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthenticatedRedirect;
