import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CSS from "../EditableServiceCard/EditableServiceCard.module.css";
import EditIcon from "@mui/icons-material/Edit";
import InstagramIcon from "@mui/icons-material/Instagram";
import Slider from "react-slick";
import {
  Button,
  IconButton,
  Switch,
  FormControlLabel,
  TextField,
  CardMedia,
  Card,
  CardContent,
  Typography,
  Chip,
  ListItem,
  List,
  Divider,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box } from "@mui/system";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import noImageFound from "../../../../assets/images/noImageFound.jpeg";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useDeleteImage,
  useDeleteItem,
  useItemUpdate,
} from "../../../../hooks/ShopUpdate";
import Grid from "@mui/system/Unstable_Grid";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";

const EditableServiceCard = ({ service, onServiceUpdate }) => {
  const [editableService, setEditableService] = useState({ ...service });
  const [isEditing, setIsEditing] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());
  // const [editableServices, setEditableServices] = useState(
  //   service.services || []
  // );

  const initialFormData = {
    title: editableService.title,
    description: editableService.description,
    phoneNumber: editableService.phoneNumber,
    instagramHandle: editableService.instagramHandle,
    price: editableService.price,
    images: editableService.images,
    services: editableService.services.length > 0 || [],
    timestamp: editableService.timestamp,
  };

  const handleServiceChange = (index, e) => {
    const updatedServices = formData.services.map((service, i) =>
      i === index ? { ...service, [e.target.name]: e.target.value } : service
    );
    setFormData({ ...formData, services: updatedServices });
  };

  const handleAddService = () => {
    setFormData({
      ...formData,
      services: [...formData.services, { type: "", rate: "" }],
    });
  };

  const handleRemoveService = (index) => {
    const updatedServices = formData.services.filter((_, i) => i !== index);
    setFormData({ ...formData, services: updatedServices });
  };

  const { debouncedUpdate, loading, error } = useItemUpdate(() => {
    onServiceUpdate(editableService);
    setIsEditing(false);
  });
  // eslint-disable-next-line no-unused-vars
  const { deleteItem, isLoadingDelete, errorDeleting } = useDeleteItem();

  const {
    deleteImage,
    // eslint-disable-next-line no-unused-vars
    isLoading: isLoadingImage,
    imageError,
  } = useDeleteImage();
  const [formData, setFormData] = useState(initialFormData);

  const truncate = (string, num) => {
    return string.length > num ? (
      <>
        {string.slice(0, num)}
        <span style={{ color: "darkgray" }}>...</span>
      </>
    ) : (
      string
    );
  };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.includes("services[")) {
      const key = name.split("[")[1].slice(0, -1); // Extracts 'type' or 'rate'
      setEditableService((prevService) => ({
        ...prevService,
        services: {
          ...prevService.services,
          [key]: type === "number" ? parseInt(value) : value,
        },
      }));
    } else if (type === "checkbox") {
      setEditableService((prevService) => ({
        ...prevService,
        [name]: checked,
      }));
    } else {
      setEditableService((prevService) => ({
        ...prevService,
        [name]: value,
      }));
    }
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleSaveChanges = () => {
    formData.timestamp = new Date().toLocaleString();
    let submissionData = {
      ...formData,
      services: JSON.stringify(formData.services),
    };
    const updatedData = new FormData();

    Object.entries(submissionData).forEach(([key, value]) => {
      if (key !== "images") {
        updatedData.append(key, value);
      }
    });

    submissionData.images.forEach((image) => {
      if (image instanceof File) {
        updatedData.append("updatedImages", image);
      }
      const imageArray = submissionData.images.filter(
        (image) => !(image instanceof File)
      );
      updatedData.append("images", JSON.stringify(imageArray));
    });

    debouncedUpdate(updatedData);
  };

  const handleDeleteImageFromDb = async (imageToRemove) => {
    const imageKey = imageToRemove.split("/").pop();
    await deleteImage(editableService._id, imageKey, service.services.type); // Adjust as needed for your backend
    if (!imageError) {
      setEditableService((prevService) => ({
        ...prevService,
        images: prevService.images.filter((image) => image !== imageToRemove),
      }));
    }
  };

  const handleRemoveImage = (index) => {
    setFormData({
      ...formData,
      images: formData.images.filter((_, i) => i !== index),
    });
    setInputKey(Date.now());
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setFormData({
      ...formData,
      images: [...formData.images, ...files],
    });
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]} ${match[3]}`;
    }
    return null;
  };

  useEffect(() => {
    setFormData({ ...editableService });
  }, [editableService]);

  const formatPrice = (price) => {
    // Assuming price is a number or a string that can be converted to a number
    const numberPrice = Number(price);
    if (isNaN(numberPrice)) return price; // Return the original price if it's not a valid number

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(numberPrice);
  };

  const renderServicesList = () => {
    if (service.services.length > 1) {
      return (
        <div>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={8}>
              <Typography variant="body1" className={CSS.cardServiceType}>
                {service.services[0].type}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                className={CSS.cardPrice}
                variant="body2"
                style={{ fontWeight: "bold" }}
              >
                {formatPrice(service.services[0].rate)}{" "}
                <span style={{ color: "gray", cursor: "pointer" }}>
                  ...more
                </span>
              </Typography>
            </Grid>
          </Grid>
        </div>
      );
    } else if (service.services.length === 1) {
      return (
        <Grid
          container
          alignItems="center"
          spacing={2}
          style={{ marginLeft: "-11px" }}
        >
          <Grid item xs={8}>
            <Typography className={CSS.cardServiceType} variant="body1">
              {service.services[0].type}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              className={CSS.cardPrice}
              variant="body2"
              style={{ fontWeight: "bold" }}
            >
              {formatPrice(service.services[0].rate)}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  return (
    <div className={CSS.editableServiceCard}>
      {loading && <p>Updating...</p>}
      {error && <p>Error updating service</p>}
      {isEditing ? (
        <div className={CSS.editingState}>
          <Slider {...sliderSettings}>
            {editableService.images.length > 0 ? (
              editableService.images.map((image) => (
                <div key={image} className={CSS.imageSlide}>
                  {" "}
                  {/* Use image URL or a better unique identifier */}
                  <img
                    src={image}
                    alt="Service Image"
                    className={CSS.editableServiceImage}
                  />
                  <button
                    onClick={() => handleDeleteImageFromDb(image)}
                    className={CSS.removeImageButton}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              ))
            ) : (
              <div className={CSS.imageSlide}>
                <img
                  src={noImageFound}
                  alt="Placeholder"
                  className={CSS.editableServiceImage}
                />
              </div>
            )}
          </Slider>
          <TextField
            label="Title"
            type="text"
            name="title"
            variant="outlined"
            fullWidth
            value={editableService.title}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="Description"
            name="description"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={editableService.description}
            onChange={handleInputChange}
            margin="normal"
          />
          <List>
            {formData.services.map((s, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={8}>
                      <TextField
                        label="Service Type"
                        variant="outlined"
                        fullWidth
                        value={s.type}
                        onChange={(e) =>
                          handleServiceChange(index, "type", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Rate"
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={s.rate}
                        onChange={(e) =>
                          handleServiceChange(
                            index,
                            "rate",
                            parseInt(e.target.value, 10)
                          )
                        }
                      />
                    </Grid>
                    <Grid item>
                      <IconButton onClick={() => handleRemoveService(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </ListItem>
                {index < formData.services.length - 1 && <Divider />}
              </React.Fragment>
            ))}
            <ListItem>
              <Button
                startIcon={<AddCircleOutline />}
                onClick={handleAddService}
              >
                Add Service
              </Button>
            </ListItem>
          </List>

          <FormControlLabel
            control={
              <Switch
                checked={editableService.active}
                onChange={handleInputChange}
                name="active"
              />
            }
            label="Active"
          />
          <TextField
            label="Contact Number"
            type="text"
            name="contactNumber"
            variant="outlined"
            fullWidth
            value={editableService.contactNumber}
            onChange={handleInputChange}
            margin="normal"
          />
          <input
            accept="image/*"
            type="file"
            id="file-input"
            style={{ display: "none" }}
            multiple
            onChange={handleImageUpload}
            key={inputKey}
          />
          <label htmlFor="file-input">
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon />}
            >
              Upload Images
            </Button>
          </label>
          {formData.images && formData.images.length > 0 && (
            <Box mt={2}>
              <div>Uploaded Images:</div>
              {formData.images.map((image, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <span>
                    {typeof image === "string"
                      ? image.split("/").pop()
                      : image.name}
                  </span>
                  <IconButton onClick={() => handleRemoveImage(index)}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}
          <div className={CSS.editButtons}>
            <button onClick={handleSaveChanges}>Save</button>
            <button onClick={() => setIsEditing(false)}>Cancel</button>
            <button
              className={CSS.deleteButton} // This applies the style
              onClick={() =>
                deleteItem(editableService._id, editableService.category)
              }
            >
              Delete
            </button>
          </div>
        </div>
      ) : (
        <>
          <Card
            className={CSS.serviceCardContainer}
            style={{
              transition: "transform 0.3s ease",
            }}
          >
            {editableService.images && service.images.length > 0 && (
              <Slider {...sliderSettings}>
                {editableService.images.map((image, index) => (
                  <CardMedia
                    key={index}
                    component="img"
                    image={image}
                    alt={service.title}
                    className={CSS.serviceImage}
                  />
                ))}
              </Slider>
            )}

            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {service.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className={CSS.serviceDescription}
              >
                {truncate(service.description, 23)}
              </Typography>
              <Chip
                label={service.subcategory}
                variant="outlined"
                style={{
                  marginTop: "4px",
                  marginBottom: "10px",
                  backgroundColor: "gray",
                  ...(window.innerWidth < 600
                    ? { fontSize: "0.7rem", height: "26px" }
                    : {}),
                }}
              />
              <div className={CSS.contactInfo}>
                <a
                  href={`tel:${service.contactNumber}`}
                  className={CSS.cardContact}
                >
                  {formatPhoneNumber(service.contactNumber)}
                </a>

                {service.instagramHandle && (
                  <a
                    href={`https://www.instagram.com/${service.instagramHandle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={CSS.instagramIcon}
                  >
                    <InstagramIcon
                      style={{ color: "#E1306C", fontSize: "1.5em" }}
                    />
                  </a>
                )}
              </div>

              {renderServicesList()}
              <EditIcon
                className={CSS.editIcon}
                style={{ color: "red" }}
                onClick={() => setIsEditing(true)}
              />
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
};

EditableServiceCard.propTypes = {
  service: PropTypes.object.isRequired,
  onServiceUpdate: PropTypes.func.isRequired,
};

export default EditableServiceCard;
