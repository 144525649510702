import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Button, TextField, Grid, Box, MenuItem } from "@mui/material";

const Section = ({
  sectionName,
  fields,
  editing,
  handleInputChange,
  currentFormState,
}) => {
  return (
    <Grid container spacing={2}>
      {fields.map((field, fieldIndex) => {
        const { label, defaultValue, type, choices, required, editable } =
          field;
        const fieldName = `${sectionName}-section-field-${fieldIndex}`;
        const isDisabled = !editing || editable === false;
        const isRequired = required && editable !== false;

        if (type === "text") {
          return (
            <TextField
              key={fieldIndex}
              fullWidth
              required={isRequired}
              disabled={isDisabled}
              id={fieldName}
              name={fieldName}
              label={label}
              variant="outlined"
              value={
                currentFormState &&
                currentFormState[sectionName] &&
                currentFormState[sectionName][fieldName]
                  ? currentFormState[sectionName][fieldName]
                  : defaultValue
              }
              onChange={(event) => handleInputChange(event, sectionName)}
              size="small"
              sx={{
                mb: 2,
                mt: fieldIndex === 0 ? 2 : 0,
              }}
            />
          );
        } else if (type === "select") {
          return (
            <TextField
              key={fieldIndex}
              fullWidth
              required={isRequired}
              disabled={isDisabled}
              id={fieldName}
              name={fieldName}
              label={label}
              variant="outlined"
              value={
                currentFormState &&
                currentFormState[sectionName] &&
                currentFormState[sectionName][fieldName]
                  ? currentFormState[sectionName][fieldName]
                  : defaultValue
              }
              onChange={(event) => handleInputChange(event, sectionName)}
              size="small"
              sx={{
                mb: 2,
                mt: fieldIndex === 0 ? 2 : 0,
              }}
              select
            >
              {choices.map((choice, choiceIndex) => (
                <MenuItem key={choiceIndex} value={choice}>
                  {choice}
                </MenuItem>
              ))}
            </TextField>
          );
        } else {
          return null;
        }
      })}
    </Grid>
  );
};

Section.propTypes = {
  sectionName: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      defaultValue: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["text", "select"]).isRequired,
      choices: PropTypes.arrayOf(PropTypes.string),
      required: PropTypes.bool,
      editable: PropTypes.bool,
    })
  ).isRequired,
  editing: PropTypes.bool.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  currentFormState: PropTypes.object,
};

const MultiSectionForm = ({ SectionToFormFields, onFormsSave }) => {
  const [editing, setEditing] = useState(false);
  const [initialFormState, setInitialFormState] = useState(null);
  const [currentFormState, setCurrentFormState] = useState(null);

  const sectionToFormFieldsRef = useRef(null);

  useEffect(() => {
    if (SectionToFormFields) {
      sectionToFormFieldsRef.current = SectionToFormFields;

      const defaultValues = {};
      Object.keys(SectionToFormFields).forEach((sectionName) => {
        defaultValues[sectionName] = {};
        SectionToFormFields[sectionName].forEach((field, index) => {
          defaultValues[sectionName][`${sectionName}-section-field-${index}`] =
            field.defaultValue;
        });
      });

      setInitialFormState(defaultValues);
      setCurrentFormState(defaultValues);
    }
  }, [SectionToFormFields]);

  const handleInputChange = (event, sectionName) => {
    const { name, value } = event.target;
    const fieldName =
      name.split("-")[0] === sectionName ? name : `${sectionName}-${name}`;

    setCurrentFormState((prevState) => {
      const updatedSection = {
        ...prevState[sectionName],
        [fieldName]: value,
      };

      return {
        ...prevState,
        [sectionName]: updatedSection,
      };
    });
  };

  const handleToggleEdit = () => {
    setEditing(!editing);
  };

  const toCamelCase = (str) => {
    if (str.indexOf(" ") === -1) {
      return str.toLowerCase();
    }

    return str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());
  };

  const extractFormData = (currentFormState) => {
    const formData = {};

    Object.keys(currentFormState).forEach((sectionName) => {
      formData[sectionName] = {};
      Object.keys(currentFormState[sectionName]).forEach((key) => {
        const fieldIndex = key.split("-section-field-")[1];
        const fieldData =
          sectionToFormFieldsRef.current[sectionName][fieldIndex];
        const formattedKey = toCamelCase(fieldData.label);
        formData[sectionName][formattedKey] =
          currentFormState[sectionName][key];
      });
    });

    return formData;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = extractFormData(currentFormState);
    onFormsSave(formData);
    handleToggleEdit();
  };

  const handleCancel = () => {
    setEditing(false);
  };

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ minHeight: "100vh", backgroundColor: "#212529" }}
    >
      <Grid item xs={12} sm={8} md={6}>
        {currentFormState && (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            {Object.keys(SectionToFormFields).map((sectionName, index) => (
              <Box key={index}>
                <Section
                  sectionName={sectionName}
                  fields={SectionToFormFields[sectionName]}
                  editing={editing}
                  handleInputChange={handleInputChange}
                  currentFormState={currentFormState}
                />
              </Box>
            ))}
            <Grid container spacing={2} justifyContent="flex-end">
              {!editing && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleToggleEdit}
                  >
                    Edit
                  </Button>
                </Grid>
              )}
              {editing && (
                <>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={
                        JSON.stringify(initialFormState) ===
                        JSON.stringify(currentFormState)
                      }
                    >
                      Save
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        )}
      </Grid>
    </Grid>
  );
};

MultiSectionForm.propTypes = {
  SectionToFormFields: PropTypes.object.isRequired,
  onFormsSave: PropTypes.func.isRequired,
};

export default MultiSectionForm;
