import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import FlagIcon from "@mui/icons-material/Flag";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Button, TextField } from "@mui/material";
import { RoomContext } from "../../context/RoomContext";
import { useReportAction } from "../../hooks/RoomAction";
import ShimmerEffect from "../ShimmerEffect/ShimmerEffect";

const VideoCard = React.forwardRef(
  (
    {
      type,
      setAudioEnabled,
      setVideoEnabled,
      audioEnabled,
      videoEnabled,
      remoteAudioEnabled,
      ...props
    },
    ref
  ) => {
    const [isReportFormOpen, setIsReportFormOpen] = useState(false);
    const [reportText, setReportText] = useState("");
    const { roomName } = useContext(RoomContext);
    const [reportAction] = useReportAction({ roomName });
    const [isMobile, setIsMobile] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [videoReady, setVideoReady] = useState(false);

    const handleReportUser = () => {
      setIsReportFormOpen(!isReportFormOpen);
    };

    const handleSubmitReport = (reportType) => {
      reportAction(reportText, reportType);
      setReportText("");
      setIsReportFormOpen(false);
    };

    const isMobileDevice = () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    };

    useEffect(() => {
      setIsMobile(isMobileDevice());
    }, [isMobile]);

    return (
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          height: "100%",
          borderRadius: "15px",
          alignItems: "center",
          justifyContent: "center",
          ...(isMobile && {
            "@media screen and (orientation: landscape)": {
              width: "100%",
              height: "61vh",
            },
          }),
        }}
      >
        {!videoReady && <ShimmerEffect />}
        <Paper
          elevation={3}
          component="video"
          ref={ref}
          sx={{
            position: "relative",
            opacity: videoReady ? 1 : 0,
            maxWidth: "100%",
            maxHeight: "100%",
            borderRadius: "5px",
            transform: "rotateY(180deg)",
            webkitTransform: "rotateY(180deg)",
            mozTransform: "rotateY(180deg)",
          }}
          onLoadedData={() => setVideoReady(true)}
          {...props}
        />
        {type === "remote" && (
          <>
            <Tooltip title="Report user">
              <IconButton
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  opacity: 0.5,
                  transition: "opacity 300ms ease-in-out",
                  "&:hover": {
                    opacity: 1,
                  },
                  zIndex: 2,
                }}
                onClick={handleReportUser}
              >
                <FlagIcon
                  sx={{
                    color: "#0D6EFD",
                  }}
                />
              </IconButton>
            </Tooltip>
            {!remoteAudioEnabled && (
              <MicOffIcon
                sx={{
                  position: "absolute",
                  bottom: 8,
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "red",
                  zIndex: 2,
                }}
              />
            )}
          </>
        )}
        {type === "remote" && isReportFormOpen && (
          <>
            <Box
              component="form"
              sx={{
                position: "absolute",
                top: 50,
                right: 5,
                "& .MuiTextField-root": { m: 1, width: "25ch" },
                zIndex: 2,
              }}
              noValidate
              autoComplete="on"
            >
              <>
                <TextField
                  id="outlined-multiline-static"
                  label="Report"
                  multiline
                  rows={3}
                  placeholder="Reason for report..."
                  value={reportText}
                  onChange={(e) => setReportText(e.target.value)}
                  sx={{
                    color: "white",
                    zIndex: 2,
                    borderRadius: "5px",
                  }}
                />
              </>
            </Box>
            <Button
              variant="contained"
              onClick={() => handleSubmitReport("reportUser")}
              sx={{
                position: "absolute",
                top: 168,
                right: 15,
                zIndex: 3,
              }}
            >
              Report
            </Button>
            <Button
              variant="contained"
              onClick={() => handleSubmitReport("reportBlock")}
              sx={{
                position: "absolute",
                top: 210,
                right: 15,
                zIndex: 3,
                backgroundColor: "red",
              }}
            >
              Report & block
            </Button>
          </>
        )}

        <Box
          sx={{
            position: "absolute",
            bottom: 8,
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "space-around",
            zIndex: 1,
            width: 150,
          }}
        >
          {type === "local" && (
            <>
              <IconButton
                sx={{
                  position: "absolute",
                  bottom: 8,
                  left: 8,
                  opacity: 0.8,
                  transition: "opacity 300ms ease-in-out",
                  background: "black",
                  "&:hover": {
                    opacity: 1,
                    backgroundColor: "#3f51b5",
                  },
                }}
                onClick={() => setAudioEnabled(!audioEnabled)}
              >
                {audioEnabled ? (
                  <MicIcon
                    sx={{
                      color: "white",
                      zIndex: 2,
                    }}
                  />
                ) : (
                  <MicOffIcon
                    sx={{
                      color: "red",
                      zIndex: 2,
                    }}
                  />
                )}
              </IconButton>

              <IconButton
                sx={{
                  position: "absolute",
                  bottom: 8,
                  right: 8,
                  opacity: 0.8,
                  transition: "opacity 300ms ease-in-out",
                  background: "black",
                  "&:hover": {
                    opacity: 1,
                    backgroundColor: "#3f51b5",
                  },
                }}
                onClick={() => setVideoEnabled(!videoEnabled)}
              >
                {videoEnabled ? (
                  <VideocamIcon
                    sx={{
                      color: "white",
                      zIndex: 2,
                    }}
                  />
                ) : (
                  <VideocamOffIcon
                    sx={{
                      color: "red",
                      zIndex: 2,
                    }}
                  />
                )}
              </IconButton>
            </>
          )}
        </Box>
      </Box>
    );
  }
);

VideoCard.displayName = "VideoCard";

VideoCard.propTypes = {
  type: PropTypes.string.isRequired,
  setAudioEnabled: PropTypes.func.isRequired,
  setVideoEnabled: PropTypes.func.isRequired,
  audioEnabled: PropTypes.bool.isRequired,
  videoEnabled: PropTypes.bool.isRequired,
  remoteAudioEnabled: PropTypes.bool,
};

export default VideoCard;
