import React from "react";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import CSS from "./ShimmerLoader.module.css";

const SkeletonLoader = ({ count }) => {
  const skeletons = [];

  for (let i = 0; i < count; i++) {
    skeletons.push(
      <div key={i} className={CSS.skeletonCard}>
        <Skeleton height={200} />
        <Skeleton height={20} width={150} style={{ marginTop: "10px" }} />
        <Skeleton height={20} width={80} />
        <Skeleton height={50} width={200} />
      </div>
    );
  }
  return <div className={CSS.skeletonLoader}>{skeletons}</div>;
};

SkeletonLoader.propTypes = {
  count: PropTypes.number.isRequired,
};

export default SkeletonLoader;
