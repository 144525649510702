import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useContext } from "react";
import { MediaContext } from "../../context/MediaContext";
import { APP_NAME } from "../../constants/constants";
import HomeIcon from "@mui/icons-material/Home";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import InfoIcon from "@mui/icons-material/Info";
import LogoutIcon from "@mui/icons-material/Logout";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import StorefrontIcon from "@mui/icons-material/Storefront";

const textColor = "#D3D3D3";
const backgroundColor = "#212529";
const activeItemColor = "#3f51b5";
const inactiveItemColor = "#D3D3D3";

const NavBar = ({ onLogout }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { releaseMedia } = useContext(MediaContext);
  const location = useLocation();

  const [selectedItem, setSelectedItem] = useState(location.pathname);

  const theme = useTheme();

  const handleLogout = async () => {
    await releaseMedia();
    onLogout();
  };

  const navItems = [
    {
      name: "Home",
      id: "home",
      link: "/dashboard",
      icon: (
        <HomeIcon
          sx={{
            color:
              selectedItem === "/dashboard"
                ? activeItemColor
                : inactiveItemColor,
            padding: "2px",
            marginBottom: "3px",
          }}
        />
      ),
    },
    {
      name: "Profile",
      id: "profile",
      link: "/profile",
      icon: (
        <PersonOutlineIcon
          sx={{
            color:
              selectedItem === "/profile" ? activeItemColor : inactiveItemColor,
            padding: "2px",
            marginBottom: "3px",
          }}
        />
      ),
    },
    {
      name: "About",
      id: "about",
      link: "/about",
      icon: (
        <InfoIcon
          sx={{
            color:
              selectedItem === "/about" ? activeItemColor : inactiveItemColor,
            padding: "2px",
            marginBottom: "3px",
          }}
        />
      ),
    },
    {
      name: "Shop",
      id: "shop",
      link: "/shop",
      icon: (
        <StorefrontIcon
          sx={{
            color:
              selectedItem === "/shop" ? activeItemColor : inactiveItemColor,
            padding: "2px",
            marginBottom: "3px",
          }}
        />
      ),
    },
    {
      name: "Leaderboard",
      id: "leaderboard",
      link: "/leaderboard",
      icon: (
        <LeaderboardIcon
          sx={{
            color:
              selectedItem === "/leaderboard"
                ? activeItemColor
                : inactiveItemColor,
            padding: "2px",
            marginBottom: "3px",
          }}
        />
      ),
    },
    {
      name: "Logout",
      icon: (
        <LogoutIcon
          sx={{
            color: theme.palette.error.main,
            padding: "2px",
            marginBottom: "3px",
          }}
        />
      ),
      callback: async () => {
        await handleLogout();
      },
    },
  ];
  const handleItemClick = (link) => {
    setSelectedItem(link);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const isVerySmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const drawerWidth =
    isVerySmallScreen || isSmallScreen ? 200 : isMediumScreen ? 240 : 300;

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography
        variant="h6"
        sx={{
          my: 2,
          color: activeItemColor,
          fontSize: "2rem",
          fontFamily: "'Chalkboard SE', 'Comic Sans MS', cursive",
        }}
      >
        <Link
          to="/dashboard"
          style={{ textDecoration: "none", color: theme.palette.primary }}
          onClick={() => {
            handleItemClick("/dashboard");
          }}
        >
          {APP_NAME}
        </Link>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem
            key={item.name}
            sx={{ color: theme.palette.primary }}
            disablePadding
          >
            <ListItemButton
              sx={{
                textAlign: "center",
                width: "100%",
                color:
                  selectedItem === item.link
                    ? theme.palette.primary
                    : "transparent",
              }}
              onClick={() => {
                if (item.callback) {
                  item.callback();
                }
                handleItemClick(item.link);
              }}
            >
              {item.link ? (
                <Link
                  to={item.link}
                  style={{
                    textDecoration: "none",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "center",
                      textDecoration: "none",
                      color:
                        item.name === "Logout"
                          ? theme.palette.error.main
                          : selectedItem === item.link
                          ? activeItemColor
                          : inactiveItemColor,
                    }}
                  >
                    {item.icon}
                    {item.name}
                  </Typography>
                </Link>
              ) : (
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "center",
                    textDecoration: "none",
                    color:
                      item.name === "Logout"
                        ? theme.palette.error.main
                        : selectedItem === item.link
                        ? theme.palette.primary
                        : textColor,
                    width: "100%",
                  }}
                >
                  {item.icon}
                  {item.name}
                </Typography>
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        sx={{
          backgroundColor: backgroundColor,
          backgroundImage: "none",
          boxShadow: "none",
          height: "64px",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block" },
              color: activeItemColor,
              fontSize: "2.0rem",
              fontWeight: "bold",
              fontFamily: "'Chalkboard SE', 'Comic Sans MS', cursive",
            }}
          >
            <Link
              to="/dashboard"
              style={{ textDecoration: "none", color: theme.palette.primary }}
              onClick={() => {
                handleItemClick("/dashboard");
              }}
            >
              {APP_NAME}
            </Link>
          </Typography>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {navItems.map((item) => (
              <Button
                key={item.name}
                sx={{
                  color:
                    item.name === "Logout"
                      ? theme.palette.error.main
                      : selectedItem === item.link
                      ? theme.palette.primary
                      : textColor,
                }}
                onClick={() => {
                  if (item.callback) {
                    item.callback();
                  }
                  handleItemClick(item.link);
                }}
              >
                {item.link ? (
                  <Link
                    to={item.link}
                    style={{
                      textDecoration: "none",
                      color:
                        item.link === selectedItem
                          ? activeItemColor
                          : inactiveItemColor,
                    }}
                  >
                    {item.icon}
                    {item.name}
                  </Link>
                ) : (
                  <>
                    {item.icon}
                    {item.name}
                  </>
                )}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: backgroundColor,
              backgroundImage: "none",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

NavBar.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default NavBar;
