import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

const VideoGrid = ({ children }) => {
  return (
    <Grid container spacing={2}>
      {React.Children.map(children, (child, index) => (
        <Grid item xs={12} sm={6} style={{ flexGrow: 1 }} key={index}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

VideoGrid.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VideoGrid;
