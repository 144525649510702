export const getSectionToFormFields = (userData) => {
  return {
    Personal: [
      {
        label: "First Name",
        defaultValue: userData.firstName,
        type: "text",
        editable: false,
      },
      {
        label: "Last Name",
        defaultValue: userData.lastName,
        type: "text",
        editable: false,
      },
    ],
    School: [
      {
        label: "School Name",
        defaultValue: userData.school.schoolName,
        type: "text",
        editable: false,
      },
      {
        label: "Classification",
        defaultValue: userData.school.classification,
        type: "select",
        choices: [
          "Freshman",
          "Sophomore",
          "Junior",
          "Senior",
          "Graduate Student",
        ],
        required: true,
      },
    ],
    Socials: [
      {
        label: "Twitter Username",
        defaultValue: userData.socials.twitter,
        type: "text",
        required: false,
      },
      {
        label: "SnapChat Username",
        defaultValue: userData.socials.snapchat,
        type: "text",
        required: false,
      },
      {
        label: "FaceBook Username",
        defaultValue: userData.socials.facebook,
        type: "text",
        required: false,
      },
      {
        label: "Instagram Username",
        defaultValue: userData.socials.instagram,
        type: "text",
        required: false,
      },
      {
        label: "TikTok Username",
        defaultValue: userData.socials.tiktok,
        type: "text",
        required: false,
      },
    ],
  };
};
