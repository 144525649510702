import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import axios from "axios";
import { Box } from "@mui/material";
import Loading from "../../components/Loading/Loading";
import { useNavigate } from "react-router-dom";

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const loadingSpinner = (
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <Loading />
  </Box>
);

const PostLogin = ({ setIsLoginSuccessful }) => {
  const { isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();
  const navigate = useNavigate();
  useEffect(() => {
    const sendUserInfo = async () => {
      if (isAuthenticated && user) {
        const token = await getAccessTokenSilently();
        const selectedSchoolId = localStorage.getItem("selectedSchoolId");
        const loginInfo = { ...user, collegeId: selectedSchoolId };

        try {
          await axios.post(`${SERVER_BASE_URL}/post-login`, loginInfo, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setIsLoginSuccessful(true);
          navigate("/dashboard");
        } catch (error) {
          if (error.response) {
            alert(error.response.data.message);
          }
          setIsLoginSuccessful(false);
          logout({ logoutParams: { returnTo: window.location.origin } });
        }
      }
    };

    sendUserInfo();
  }, [
    isAuthenticated,
    user,
    getAccessTokenSilently,
    navigate,
    logout,
    setIsLoginSuccessful,
  ]);

  return loadingSpinner;
};

PostLogin.propTypes = {
  setIsLoginSuccessful: PropTypes.func.isRequired,
};

export default PostLogin;
