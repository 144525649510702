import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CSS from "../EditableProductCard/EditableProductCard.module.css";
import EditIcon from "@mui/icons-material/Edit";
import Slider from "react-slick";
import {
  useItemUpdate,
  useDeleteImage,
  useDeleteItem,
} from "../../../../hooks/ShopUpdate";
import { Button, IconButton } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box } from "@mui/system";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import noImageFound from "../../../../assets/images/noImageFound.jpeg";
import DeleteIcon from "@mui/icons-material/Delete";

const EditableProductCard = ({ product, onProductUpdate }) => {
  const [editableProduct, setEditableProduct] = useState({ ...product });
  const [isEditing, setIsEditing] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());
  const { debouncedUpdate, loading, error } = useItemUpdate(() => {
    onProductUpdate(editableProduct);
    setIsEditing(false);
  });
  // eslint-disable-next-line no-unused-vars
  const { deleteItem, isLoadingDelete, errorDeleting } = useDeleteItem();

  const initialFormData = {
    title: editableProduct.title,
    description: editableProduct.description,
    phoneNumber: editableProduct.phoneNumber,
    instagramHandle: editableProduct.instagramHandle,
    price: editableProduct.price,
    images: editableProduct.images,
    timestamp: editableProduct.timestamp,
  };

  // eslint-disable-next-line no-unused-vars
  const { deleteImage, isLoading, imageError } = useDeleteImage();
  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]} ${match[3]}`;
    }
    return null;
  };

  const handleSaveChanges = () => {
    formData.timestamp = new Date().toLocaleString();
    const updateData = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      if (key !== "images") {
        updateData.append(key, value);
      }
    });

    formData.images.forEach((image) => {
      if (image instanceof File) {
        updateData.append("updatedImages", image);
      }
      const imageArray = formData.images.filter(
        (image) => !(image instanceof File)
      );
      updateData.append("images", JSON.stringify(imageArray));
    });

    debouncedUpdate(updateData);
  };

  const handleDeleteImageFromDb = async (imageToRemove) => {
    const imageKey = imageToRemove.split("/").pop(); // Extract the key from the URL
    try {
      await deleteImage(editableProduct._id, imageKey, product.category);
      if (!imageError) {
        setEditableProduct((prevProduct) => {
          return {
            ...prevProduct,
            images: prevProduct.images.filter(
              (image) => image !== imageToRemove
            ),
          };
        });
      }
    } catch (error) {
      console.error("Error during image deletion:", error);
      // Handle error appropriately
    }
  };

  const handleRemoveImage = (index) => {
    setFormData({
      ...formData,
      images: formData.images.filter((_, i) => i !== index),
    });
    setInputKey(Date.now()); // Reset the key of the input to force re-render
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);

    // Update the formData state to include the actual file objects
    setFormData({
      ...formData,
      images: [...formData.images, ...files],
    });
  };

  useEffect(() => {
    setFormData({ ...editableProduct });
  }, [editableProduct]);

  return (
    <div className={CSS.editableProductCard}>
      {loading && <p>Updating...</p>}
      {error && <p>Error fetching products</p>}
      {isEditing ? (
        <div className={CSS.editingState}>
          <Slider {...sliderSettings}>
            {editableProduct.images.length > 0 ? (
              editableProduct.images.map((image) => (
                <div key={image} className={CSS.imageSlide}>
                  {" "}
                  {/* Use image URL or a better unique identifier */}
                  <img
                    src={image}
                    alt="Product Image"
                    className={CSS.editableProductImage}
                  />
                  <button
                    onClick={() => handleDeleteImageFromDb(image)}
                    className={CSS.removeImageButton}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              ))
            ) : (
              <div className={CSS.imageSlide}>
                <img
                  src={noImageFound}
                  alt="Placeholder"
                  className={CSS.editableProductImage}
                />
              </div>
            )}
          </Slider>
          <span>
            Title:{" "}
            <input
              type="text"
              name="title"
              value={editableProduct.title}
              onChange={handleInputChange}
            />
          </span>
          <span>
            Description:{" "}
            <textarea
              className={CSS.editProductDescription}
              name="description"
              value={editableProduct.description}
              onChange={handleInputChange}
            />
          </span>
          <span>
            Price ($):{" "}
            <input
              type="text"
              name="price"
              value={editableProduct.price}
              onChange={handleInputChange}
            />
          </span>
          <span>
            Contact Number:{" "}
            <input
              type="text"
              name="contactNumber"
              value={editableProduct.contactNumber}
              onChange={handleInputChange}
            />
          </span>
          <span>
            IG Handle:{" "}
            <input
              type="text"
              name="instagramHandle"
              value={editableProduct.instagramHandle}
              onChange={handleInputChange}
            />
          </span>
          <input
            accept="image/*"
            type="file"
            id="raised-button-file"
            style={{ visibility: "hidden" }}
            multiple
            onChange={handleImageUpload}
            key={inputKey}
          />
          <label htmlFor="raised-button-file">
            <Button
              variant="contained"
              component="span"
              color="primary"
              startIcon={<CloudUploadIcon />}
              style={{ margin: "10px 0" }}
            >
              Upload Images
            </Button>
          </label>
          {formData.images.length > 0 && (
            <Box mt={1}>
              <div>Uploaded Images:</div>
              <ul>
                {formData.images.map((file, index) => (
                  <li key={index}>
                    {file.name}
                    <IconButton onClick={() => handleRemoveImage(index)}>
                      <RemoveCircleOutline sx={{ color: "red" }} />
                    </IconButton>
                  </li> // Render the file name
                ))}
              </ul>
            </Box>
          )}
          <div className={CSS.editButtons}>
            <button onClick={handleSaveChanges}>Save</button>
            <button onClick={() => setIsEditing(false)}>Cancel</button>
            <button
              className={CSS.deleteButton} // This applies the style
              onClick={() =>
                deleteItem(editableProduct._id, editableProduct.category)
              }
            >
              Delete
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div className={CSS.cardContent}>
            <Slider {...sliderSettings}>
              {editableProduct.images.length > 0 ? (
                editableProduct.images.map((image, index) => (
                  <div key={index} className={CSS.imageSlide}>
                    <img
                      src={image}
                      alt={`Product ${index}`}
                      className={CSS.editableProductImage}
                    />
                  </div>
                ))
              ) : (
                <div className={CSS.imageSlide}>
                  <img
                    src={noImageFound}
                    alt="Placeholder"
                    className={CSS.editableProductImage}
                  />
                </div>
              )}
            </Slider>
            <h3 className={CSS.cardTitle}>{editableProduct.title}</h3>
            <p className={CSS.cardPrice}>${editableProduct.price}</p>
            <p className={CSS.cardDescription}>{editableProduct.description}</p>
          </div>
          <p>{formatPhoneNumber(product.contactNumber)}</p>
          <EditIcon
            className={CSS.editIcon}
            onClick={() => setIsEditing(true)}
          />
        </div>
      )}
    </div>
  );
};

EditableProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  onProductUpdate: PropTypes.func.isRequired,
};

export default EditableProductCard;
