import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CSS from "./ProductCard.module.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MailIcon from "@mui/icons-material/Mail";
import { formatDistanceToNow } from "date-fns";
import noImageFound from "../../../../assets/images/noImageFound.jpeg";

const ProductCard = ({ product }) => {
  const [openCard, setOpenCard] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [truncateLimit, setTruncateLimit] = useState(17);

  const handleOpenCard = () => setOpenCard(true);
  const handleCloseCard = () => setOpenCard(false);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const formatPrice = (price) => {
    // Assuming price is a number or a string that can be converted to a number
    const numberPrice = Number(price);
    if (isNaN(numberPrice)) return price; // Return the original price if it's not a valid number

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(numberPrice);
  };

  const handleContactClick = () => {
    setShowContact(true);
  };

  const humanizeTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const truncate = (string, num) => {
    return string.length > num ? (
      <>
        {string.slice(0, num)}
        <span style={{ color: "white" }}>...</span>
      </>
    ) : (
      string
    );
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]} ${match[3]}`;
    }
    return null;
  };

  const renderModalContent = () => (
    <div className={CSS.modalContainer}>
      {/* Cancel button with absolute positioning */}
      <button onClick={handleCloseCard} className={CSS.cancelButton}>
        X
      </button>
      <div
        className={CSS.productCard}
        style={{ width: "100%", maxWidth: "750px" }}
      >
        <div className={CSS.imageContainer}>
          <Slider {...sliderSettings}>
            {product.images.map((image, index) => (
              <div key={index} className={CSS.imageSlide}>
                <img
                  src={image}
                  alt={`${product.title} - ${index}`}
                  className={CSS.productImage}
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className={CSS.cardContent}>
          <h3 className={CSS.cardTitle}>{product.title}</h3>
          <p className={CSS.cardPrice}>
            {product.price !== "FREE" ? formatPrice(product.price) : "FREE"}
          </p>
          <p className={CSS.cardDescription}>{product.description}</p>
          <a href={`tel:${product.contactNumber}`} className={CSS.cardContact}>
            {formatPhoneNumber(product.contactNumber)}
          </a>{" "}
          {product.instagramHandle && (
            <a
              href={`https://www.instagram.com/${product.instagramHandle}`}
              target="_blank"
              rel="noopener noreferrer"
              className={CSS.instagramIcon}
            >
              <InstagramIcon style={{ color: "#E1306C", fontSize: "1.5em" }} />
            </a>
          )}
          {product.postedBy && (
            <p className={CSS.postedBy}>Posted by: {product.postedBy}</p>
          )}
          {product.timestamp && (
            <p className={CSS.postedAt}>
              {humanizeTimestamp(product.timestamp)}
            </p>
          )}
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setTruncateLimit(17);
      } else {
        setTruncateLimit(27);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div>
      <div className={CSS.productCard} onClick={handleOpenCard}>
        <div className={CSS.imageContainer}>
          {product.images && product.images.length > 0 ? (
            <Slider {...sliderSettings}>
              {product.images.map((image, index) => (
                <div key={index} className={CSS.imageSlide}>
                  <img
                    src={image}
                    alt={`${product.title} - ${index}`}
                    className={CSS.productImage}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <Slider {...sliderSettings}>
              <div className={CSS.imageSlide}>
                <img
                  src={noImageFound}
                  alt={`${product.title}`}
                  className={CSS.productImage}
                />
              </div>
            </Slider>
          )}
        </div>
        <div className={CSS.cardContent}>
          <h3 className={CSS.cardTitle}>{product.title}</h3>
          <p className={CSS.cardPrice}>
            {product.price !== "FREE" ? formatPrice(product.price) : "FREE"}
          </p>

          <p className={CSS.cardDescription}>
            {truncate(product.description, truncateLimit)}
          </p>
          <div className={CSS.contactInfo}>
            {!showContact ? (
              <div className={CSS.contactLink} onClick={handleContactClick}>
                <MailIcon style={{ fontSize: "1.2em", marginRight: "5px" }} />
                Contact me
              </div>
            ) : (
              <a
                href={`tel:${product.contactNumber}`}
                className={CSS.cardContact}
              >
                {formatPhoneNumber(product.contactNumber)}
              </a>
            )}
            {product.instagramHandle && (
              <a
                href={`https://www.instagram.com/${product.instagramHandle}`}
                target="_blank"
                rel="noopener noreferrer"
                className={CSS.instagramIcon}
              >
                <InstagramIcon
                  style={{ color: "#E1306C", fontSize: "1.5em" }}
                />
              </a>
            )}
          </div>
        </div>
      </div>
      <Modal open={openCard} onClose={handleCloseCard}>
        <Box className={CSS.modalContent}>{renderModalContent()}</Box>
      </Modal>
    </div>
  );
};
ProductCard.propTypes = {
  product: PropTypes.node.isRequired,
};

export default ProductCard;
