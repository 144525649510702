import React from "react";
import PropTypes from "prop-types";
import { IconButton as MUIIconButton, Tooltip } from "@mui/material";

const defaultButtonStyle = {
  width: "35px",
  height: "35px",
  borderRadius: "50%",
  padding: "7px",
  backgroundColor: "#3f51b5",
};

const IconButton = ({ children, tooltip, disabled, ...props }) => {
  return (
    <div>
      <Tooltip title={tooltip || ""}>
        <span>
          <MUIIconButton {...props} disabled={!!disabled}>
            {React.cloneElement(children, { sx: defaultButtonStyle })}
          </MUIIconButton>
          {disabled && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none",
              }}
            ></div>
          )}
        </span>
      </Tooltip>
    </div>
  );
};

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default IconButton;
