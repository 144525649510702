import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useAuthToken } from "./UseAuthToken";

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const useFriendsData = (initialPage = 1, limit = 9) => {
  const [friends, setFriends] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(0);

  const token = useAuthToken();

  const fetchData = useCallback(async () => {
    if (token) {
      setIsLoading(true);
      try {
        const response = await axios.get(`${SERVER_BASE_URL}/user/friends`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page,
            limit: limit,
            type: "unblocked",
          },
        });
        setFriends(response.data.friends);
        setPage(response.data.page);
        setTotalPages(response.data.totalPages);
        setIsLoading(false);
      } catch (e) {
        setError(e);
        setIsLoading(false);
      }
    }
  }, [page, limit, token]);

  const blockUser = async (friendId) => {
    if (token) {
      try {
        await axios.put(`${SERVER_BASE_URL}/user/friends/block`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            friendId,
          },
        });
        return true;
      } catch (e) {
        setError(e);
        return false;
      }
    }
  };

  const removeFriend = async (friendId) => {
    if (token) {
      try {
        await axios.delete(`${SERVER_BASE_URL}/user/friends/delete`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            friendId,
          },
        });
        return true;
      } catch (e) {
        setError(e);
        return false;
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, limit, fetchData]);

  return [
    friends,
    isLoading,
    error,
    setPage,
    page,
    totalPages,
    fetchData,
    blockUser,
    removeFriend,
  ];
};

const useBlockedFriendsData = (initialPage = 1, limit = 9) => {
  const [blocked, setBlocked] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(0);

  const token = useAuthToken();

  const fetchData = useCallback(async () => {
    if (token) {
      setIsLoading(true);
      try {
        const response = await axios.get(`${SERVER_BASE_URL}/user/friends`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page,
            limit: limit,
            type: "blocked",
          },
        });
        setBlocked(response.data.blocked);
        setPage(response.data.page);
        setTotalPages(response.data.totalPages);
        setIsLoading(false);
      } catch (e) {
        setError(e);
        setIsLoading(false);
      }
    }
  }, [page, limit, token]);

  const unblockUser = async (friendId) => {
    if (token) {
      try {
        await axios.put(`${SERVER_BASE_URL}/user/friends/unblock`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            friendId,
          },
        });
        return true;
      } catch (e) {
        setError(e);
        return false;
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, limit, fetchData]);

  return [
    blocked,
    isLoading,
    error,
    setPage,
    page,
    totalPages,
    fetchData,
    unblockUser,
  ];
};

export { useBlockedFriendsData, useFriendsData };
