import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider, createTheme } from "@mui/material";
import "./index.css";
import App from "./App";

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },

    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#212529",
    },
    mode: "dark",
  },
  typography: {
    fontFamily: ["'Roboto', sans-serif", "Klavika"].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 900,
      lg: 1400,
      xl: 1920,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: `${window.location.origin}/post-login`,
      audience: AUTH0_AUDIENCE,
    }}
  >
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Auth0Provider>
);
