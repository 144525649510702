import { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import { useAuth0 } from "@auth0/auth0-react";

const useSocket = (SERVER_BASE_URL) => {
  const socket = useRef(null);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [socketError, setSocketError] = useState(null);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let newSocket;

    const initializeSocketConnection = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          newSocket = io(SERVER_BASE_URL, {
            auth: { token: token },
          });

          newSocket.on("connect", () => {
            setIsSocketConnected(true);
            setSocketError(null);
          });

          newSocket.on("disconnect", () => {
            setIsSocketConnected(false);
          });

          newSocket.on("connect_error", (err) => {
            setIsSocketConnected(false);
            setSocketError(err.message);
          });

          socket.current = newSocket;
        } catch (error) {
          setSocketError("Failed to connect to the server.");
          setIsSocketConnected(false);
        }
      }
    };

    initializeSocketConnection();

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, [isAuthenticated, getAccessTokenSilently, SERVER_BASE_URL]);

  return { socket, isSocketConnected, socketError };
};

export default useSocket;
