import React, { useState } from "react";
import CSS from "./Login.module.css";
import TermsOverlay from "../../components/TermsConditions/TermsOverlay";
import { Autocomplete, Link, TextField } from "@mui/material";
import { JOIN_OUR_WAITLIST } from "../../constants/constants";
import { useFetchColleges } from "../../hooks/FetchColleges";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Loading from "../../components/Loading/Loading";
import LoginButton from "../../components/Login/LoginButton";

const providerIdMapping = {
  bbdefa2950f49882: "google",
  "9fbf261b62c1d7c0": "microsoft",
};

const loadingSpinner = (
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <Loading />
  </Box>
);

const Login = () => {
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [
    supportedColleges,
    supportedCollegesFetchLoading,
    supportedCollegesFetchError,
  ] = useFetchColleges();

  const getLoginFromProviderId = (providerId) => {
    const providerName = providerIdMapping[providerId];
    return providerName;
  };

  const JoinWaitListLink = () => {
    return (
      <Link
        rel="noopener"
        variant="body2"
        underline="none"
        href={JOIN_OUR_WAITLIST}
        target="_blank"
      >
        Not found? join our waitlist!
      </Link>
    );
  };

  const handleOnChange = (event, newValue) => {
    localStorage.setItem("selectedSchoolId", newValue.id);
    setSelectedCollege(newValue);
  };

  const renderSignInInfo = () => {
    if (!selectedCollege) return null;

    return (
      <div className={CSS.signinInfo}>
        <span className={CSS.disclaimerText}>
          *only use an email issued by your school
        </span>
        <div className={CSS.loginButton}>
          <LoginButton
            provider={getLoginFromProviderId(selectedCollege.providerId)}
          />
        </div>
        <span className={CSS.termsOverlay}>
          <TermsOverlay />
        </span>
      </div>
    );
  };

  return (
    <div className={CSS.loginContainer}>
      {!supportedCollegesFetchError &&
        supportedCollegesFetchLoading &&
        loadingSpinner}
      {!supportedCollegesFetchError && !supportedCollegesFetchLoading && (
        <div className={CSS.centeredContainer}>
          <div className={CSS.logoContainer}>
            <img
              alt="none"
              src="/favicon/sign-in-logo.png"
              height="100px"
              width="100px"
              style={{ textAlign: "center" }}
            />
          </div>
          <div className={CSS.logoText}>
            <p>Connect meaningfully through video magic 🚀</p>
          </div>

          <div className={CSS.collegeOptions}>
            <Autocomplete
              onChange={handleOnChange}
              options={supportedColleges || []}
              getOptionLabel={(college) => college.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Find your School..." />
              )}
              noOptionsText={<JoinWaitListLink />}
            />
          </div>
          <div>{renderSignInInfo()}</div>
        </div>
      )}
      {supportedCollegesFetchError && (
        <Typography variant="body1" color="white">
          An error occurred while fetching colleges
        </Typography>
      )}
    </div>
  );
};

export default Login;
