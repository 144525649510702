import { useState, useEffect } from "react";
import axios from "axios";

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const useFetchColleges = () => {
  const [error, setError] = useState(null);
  const [supportedColleges, setSupportedColleges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchColleges = () => {
      setIsLoading(true);
      axios
        .get(`${SERVER_BASE_URL}/colleges`)
        .then((response) => {
          const sortedColleges = response.data.sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });

          setSupportedColleges(sortedColleges);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchColleges();
  }, []);

  return [supportedColleges, isLoading, error];
};

export { useFetchColleges };
