import { useState } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { useAuthToken } from "./UseAuthToken";

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const useItemUpdate = (onItemUpdate) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = useAuthToken();

  const updateItemInDb = async (updatedItem) => {
    setLoading(true);
    try {
      const response = await axios.put(
        `${SERVER_BASE_URL}/api/shop/item/update`,
        updatedItem,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onItemUpdate(response.data); // Assuming this updates the UI accordingly
      setLoading(false);
      return response.data;
    } catch (error) {
      console.error("Error updating product:", error);
      setError(error);
      setLoading(false);
      throw error;
    }
  };

  // Debounce the update function to prevent too many requests to the backend
  const debouncedUpdate = debounce(updateItemInDb, 300);

  return { debouncedUpdate, loading, error };
};

const useDeleteImage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState(null);
  const token = useAuthToken();

  const deleteImage = async (itemId, imageKey, itemCategory) => {
    setIsLoading(true);
    try {
      const payload = {
        itemId,
        imageKeys: [imageKey], // The backend expects an array of keys
        itemCategory: itemCategory,
      };

      // Send the request to the backend
      await axios.post(
        `${SERVER_BASE_URL}/api/products/delete-image`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // You can also return some data here if needed
    } catch (err) {
      console.error("Error deleting image:", err);
      setImageError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return { deleteImage, isLoading, imageError };
};

const useDeleteItem = () => {
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [errorDeleting, setErrorDeleting] = useState(null);
  const token = useAuthToken();

  const deleteItem = async (itemId, category) => {
    setIsLoadingDelete(true);
    setErrorDeleting(null);

    try {
      const response = await axios.delete(
        `${SERVER_BASE_URL}/api/items/delete/${itemId}?category=${category}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete item");
      }

      setIsLoadingDelete(false);
      return true;
    } catch (err) {
      setErrorDeleting(err.message);
      setIsLoadingDelete(false);
    }
  };

  return { deleteItem, isLoadingDelete, errorDeleting };
};

export { useItemUpdate, useDeleteImage, useDeleteItem };
