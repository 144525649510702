import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import CSS from "./Loading.module.css";

const Loading = () => {
  return (
    <div className={CSS.loadingWrapper}>
      <CircularProgress style={{ color: "white" }} />
    </div>
  );
};

export default Loading;
