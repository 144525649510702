import React from "react";
import PropTypes from "prop-types";
import { Button as MuiButton, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const Button = ({ text, onClick, disabled, tooltip, color, size }) => {
  return (
    <div>
      <Tooltip
        title={tooltip || ""}
        arrow
        PopperProps={{
          popperOptions: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 8],
                },
              },
            ],
          },
          sx: {
            "& .MuiTooltip-tooltip": {
              fontSize: "15px",
            },
          },
        }}
      >
        <span>
          <MuiButton
            variant="contained"
            color="primary"
            onClick={onClick}
            disabled={!!disabled}
            size={size || "medium"}
            endIcon={tooltip ? <InfoOutlinedIcon /> : null}
            sx={{
              backgroundColor: color || "#7986cb",
              "&.Mui-disabled": {
                backgroundColor: color || "#7986cb",
                color: "rgba(255, 255, 255, 1)",
                opacity: 0.5,
                cursor: "not-allowed",
              },
            }}
          >
            {text}
          </MuiButton>
          {disabled && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none",
              }}
            ></div>
          )}
        </span>
      </Tooltip>
    </div>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
};

export default Button;
