import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faSnapchatGhost,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import CSS from "./FriendInfoCard.module.css";

const FriendInfoCard = ({ firstName, lastName, socials, school, buttons }) => {
  return (
    <Card variant="outlined" sx={{ minWidth: 190, height: "100%" }}>
      <CardContent sx={{ padding: "8px 8px 8px !important" }}>
        <Typography variant="h5" component="div" className={CSS.centeredText}>
          {firstName} {lastName}
        </Typography>
        <Box className={CSS.socials}>
          <Box className={CSS.iconWrapper}>
            {socials.twitter && (
              <a
                href={`https://twitter.com/${socials.twitter}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} size="sm" />
              </a>
            )}
            {socials.facebook && (
              <a
                href={`https://facebook.com/${socials.facebook}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} size="sm" />
              </a>
            )}
            {socials.instagram && (
              <a
                href={`https://instagram.com/${socials.instagram}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="sm" />
              </a>
            )}
            {socials.snapchat && (
              <a
                href={`https://snapchat.com/add/${socials.snapchat}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faSnapchatGhost} size="sm" />
              </a>
            )}
            {socials.tiktok && (
              <a
                href={`https://tiktok.com/${socials.tiktok}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTiktok} size="sm" />
              </a>
            )}
          </Box>
        </Box>
        {school && (
          <Box className={CSS.centeredText}>
            {school.schoolName && (
              <>
                <Typography variant="body2" component="div" sx={{ mb: 1 }}>
                  <FontAwesomeIcon icon={faGraduationCap} size="sm" />{" "}
                  {school.schoolName}
                </Typography>
              </>
            )}
            {school.classification && (
              <Typography variant="body2" component="div">
                {school.classification}
              </Typography>
            )}
          </Box>
        )}
        <CardActions className={CSS.centeredButtons}>
          {buttons.map((button) => (
            <Button size="small" key={button.name} onClick={button.callback}>
              {button.name}
            </Button>
          ))}
        </CardActions>
      </CardContent>
    </Card>
  );
};

FriendInfoCard.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  socials: PropTypes.shape({
    twitter: PropTypes.string,
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    snapchat: PropTypes.string,
    tiktok: PropTypes.string,
  }),
  school: PropTypes.shape({
    schoolName: PropTypes.string,
    classification: PropTypes.string,
  }),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default FriendInfoCard;
