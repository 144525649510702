import React from "react";
import PropTypes from "prop-types";
import { Grid, Pagination, Box } from "@mui/material";
import FriendInfoCard from "../../components/FriendInfoCard/FriendInfoCard";

const FriendsGrid = ({
  friendsData,
  page,
  totalPages,
  setPage,
  onBlockStatusChange,
  onRemoveFriendChange,
  type,
}) => {
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const onRemoveClick = (friendId) => {
    if (onRemoveFriendChange) {
      onRemoveFriendChange(friendId);
    }
  };

  const onBlockClick = (friendId) => {
    onBlockStatusChange(friendId, true);
  };

  const onUnblockClick = (friendId) => {
    onBlockStatusChange(friendId, false);
  };

  const blockedFriendButtons = [{ name: "Unblock", callback: onUnblockClick }];

  const unBlockedFriendButtons = [
    { name: "Remove", callback: onRemoveClick },
    { name: "Block", callback: onBlockClick },
  ];

  return (
    <>
      <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
        {friendsData.map((friend) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={friend.id}>
              <Box sx={{ minWidth: 190, height: "100%" }}>
                <FriendInfoCard
                  firstName={friend.firstName}
                  lastName={friend.lastName}
                  socials={friend.socials}
                  school={friend.school}
                  buttons={
                    type === "blocked"
                      ? blockedFriendButtons.map((btn) => ({
                          ...btn,
                          callback: () => btn.callback(friend.id),
                        }))
                      : unBlockedFriendButtons.map((btn) => ({
                          ...btn,
                          callback: () => btn.callback(friend.id),
                        }))
                  }
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
      {friendsData.length > 0 && (
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          sx={{ float: "right" }}
          mt={2}
        />
      )}
    </>
  );
};

FriendsGrid.propTypes = {
  friendsData: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  onBlockStatusChange: PropTypes.func.isRequired,
  onRemoveFriendChange: PropTypes.func,
  type: PropTypes.string.isRequired,
};

export default FriendsGrid;
