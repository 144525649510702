import { useState, useCallback } from "react";
import axios from "axios";
import { useAuthToken } from "./UseAuthToken";
import { useAuth0 } from "@auth0/auth0-react";

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const useShopFileUpload = () => {
  const [uploadStatus, setUploadStatus] = useState({
    state: "idle",
    message: "Submit",
  });
  const { user } = useAuth0();
  const token = useAuthToken();

  const uploadFile = useCallback(
    async (fileData) => {
      setUploadStatus({ state: "uploading", message: "Uploading..." });

      const formData = new FormData();
      Object.entries(fileData).forEach(([key, value]) => {
        if (key === "images") {
          value.forEach((file) => formData.append(key, file));
        } else {
          formData.append(key, value);
        }
      });

      if (user && user.email) {
        formData.append("email", user.email);
      }

      try {
        const response = await axios.post(
          `${SERVER_BASE_URL}/shop/upload`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadStatus({
                state: "uploading",
                message: `Uploading... ${percentCompleted}%`,
              });
            },
          }
        );
        setUploadStatus({ state: "success", message: "Upload successful!" });
        return response.data;
      } catch (error) {
        setUploadStatus({ state: "error", message: "Upload failed!" });
        throw error;
      }
    },
    [token, user]
  );

  const resetUploadStatus = useCallback(() => {
    setUploadStatus({ state: "idle", message: "Submit" });
  }, []);

  return { uploadStatus, uploadFile, resetUploadStatus };
};

export default useShopFileUpload;
