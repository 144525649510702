import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Box,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import useShopFileUpload from "../../hooks/useShopFileUpload";
import { useBasicUserData } from "../../hooks/UserData";
import CSS from "./PostItemDialog.module.css";
import { Switch, FormGroup, FormControlLabel } from "@mui/material";

const PostItemDialog = ({
  open,
  handleClose,
  addNewProduct,
  addNewService,
}) => {
  const initialService = { type: "", rate: "" };

  // eslint-disable-next-line no-unused-vars
  const [userData, isLoading, error] = useBasicUserData();
  const initialFormData = {
    title: "",
    description: "",
    category: "",
    subcategory: "",
    phoneNumber: "",
    instagramHandle: "",
    services: [initialService],
    prices: {},
    images: [],
    timestamp: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [subcategories, setSubcategories] = useState([]);
  const { uploadStatus, uploadFile, resetUploadStatus } = useShopFileUpload();
  const [isActive, setIsActive] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());

  const handleRemoveImage = (index) => {
    setFormData({
      ...formData,
      images: formData.images.filter((_, i) => i !== index),
    });
    setInputKey(Date.now()); // Reset the key of the input to force re-render
  };

  useEffect(() => {
    if (userData && userData.firstName) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        postedBy: userData.firstName,
      }));
    }
  }, [userData]);

  useEffect(() => {
    if (formData.category === "product") {
      setSubcategories(["Apparel", "Books", "Electronics", "Food", "Other"]);
    } else if (formData.category === "service") {
      setSubcategories([
        "Beauty",
        "Ghetto Uber",
        "Graphic Design",
        "Rent stuff",
        "Tutoring",
        "Other",
      ]);
    }
  }, [formData.category]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      const cleanedValue = value.replace(/\D/g, "").slice(0, 10);
      setFormData({ ...formData, [name]: cleanedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleServiceChange = (index, e) => {
    const updatedServices = formData.services.map((service, i) =>
      i === index ? { ...service, [e.target.name]: e.target.value } : service
    );
    setFormData({ ...formData, services: updatedServices });
  };

  const addServiceField = () => {
    setFormData({
      ...formData,
      services: [...formData.services, initialService],
    });
  };

  const removeServiceField = (index) => {
    const updatedServices = formData.services.filter((_, i) => i !== index);
    setFormData({ ...formData, services: updatedServices });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let modifiedServices;
    if (formData.category === "service") {
      modifiedServices = formData.services.map((service) => {
        if (!service.rate || service.rate === 0) {
          return { ...service, rate: "FREE" };
        }
        return service;
      });
    } else {
      modifiedServices = formData.services;
    }
    console.log("MODIFIED SERVICES", modifiedServices);
    let submissionData = {
      ...formData,
      services: JSON.stringify(modifiedServices),
      timestamp: new Date().toLocaleString(),
      active: formData.category === "service" ? isActive : null,
    };

    if (
      submissionData.category === "product" &&
      (!submissionData.price || submissionData.price === "0")
    ) {
      submissionData.price = "FREE";
    }

    try {
      const response = await uploadFile(submissionData);
      if (
        submissionData.category === "product" &&
        response &&
        response.message === "Product saved successfully!"
      ) {
        addNewProduct({ ...submissionData, images: response.uploadedImages });
      } else if (
        submissionData.category === "service" &&
        response &&
        response.message === "Service item inserted successfully"
      ) {
        addNewService({
          ...submissionData,
          services: modifiedServices,
          images: response.uploadedImages,
        });
      }
      setFormData(initialFormData);
      resetUploadStatus();
      handleClose();
    } catch (err) {
      // console.error(err);
    }
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);

    // Update the formData state to include the actual file objects
    setFormData({
      ...formData,
      images: [...formData.images, ...files],
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ backgroundColor: "#212529" }}>
        Add a Product or Service
      </DialogTitle>
      <DialogContent className={CSS.postItemDialog}>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              multiline
              rows={4}
              value={formData.description}
              onChange={handleInputChange}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Contact Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              error={
                formData.phoneNumber.length !== 0 &&
                formData.phoneNumber.length !== 10
              }
              helperText={
                formData.phoneNumber.length !== 0 &&
                formData.phoneNumber.length !== 10
                  ? "Phone number must be exactly 10 digits"
                  : ""
              }
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="IG Handle"
              name="instagramHandle"
              value={formData.instagramHandle}
              onChange={handleInputChange}
            />
          </Box>
          <Box mb={2}>
            <FormControl fullWidth required>
              <InputLabel>Category</InputLabel>
              <Select
                name="category"
                value={formData.category}
                label="Category"
                onChange={handleInputChange}
              >
                <MenuItem value="product">Product</MenuItem>
                <MenuItem value="service">Service</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl fullWidth required>
              <InputLabel>Subcategory</InputLabel>
              <Select
                name="subcategory"
                value={formData.subcategory}
                label="Subcategory"
                onChange={handleInputChange}
                disabled={subcategories.length === 0}
              >
                {subcategories.map((subcat) => (
                  <MenuItem key={subcat} value={subcat} required>
                    {subcat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {formData.category === "service" && (
            <Box mb={2}>
              <FormGroup row>
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      checked={isActive}
                      onChange={(e) => setIsActive(e.target.checked)}
                      name="active"
                    />
                  }
                  label="Available now"
                  labelPlacement="start"
                  style={{ marginLeft: "7px" }} // This ensures the label is aligned to the left and the switch to the right
                />
              </FormGroup>
            </Box>
          )}

          {formData.category === "product" && (
            <Box mb={2}>
              <TextField
                fullWidth
                label="Price ($)"
                type="number"
                name="price"
                onChange={handleInputChange}
                value={formData.price}
                required
              />
            </Box>
          )}
          {formData.category === "service" &&
            formData.services.map((service, index) => (
              <Box key={index} display="flex" alignItems="center" mb={2}>
                <TextField
                  label="Service Type"
                  name="type"
                  value={service.type}
                  onChange={(e) => handleServiceChange(index, e)}
                  required
                  style={{ marginRight: "10px" }}
                />
                <TextField
                  label="Rate ($)"
                  name="rate"
                  type="number"
                  value={service.rate}
                  onChange={(e) => handleServiceChange(index, e)}
                  required
                />
                {formData.services.length > 1 && (
                  <IconButton onClick={() => removeServiceField(index)}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                )}
                {index === formData.services.length - 1 &&
                  formData.services.length < 5 && (
                    <IconButton onClick={addServiceField}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                  )}
              </Box>
            ))}

          <Box mb={2} style={{ width: "100%" }}>
            <input
              accept="image/*"
              type="file"
              id="raised-button-file"
              style={{ visibility: "hidden" }}
              multiple
              onChange={handleImageUpload}
              key={inputKey}
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="contained"
                component="span"
                color="primary"
                startIcon={<CloudUploadIcon />}
                style={{ margin: "10px 0" }}
              >
                Upload Images
              </Button>
            </label>
            {formData.images.length > 0 && (
              <Box mt={1}>
                <div>Uploaded Images:</div>
                <ul>
                  {formData.images.map((file, index) => (
                    <li key={index}>
                      {file.name}
                      <IconButton onClick={() => handleRemoveImage(index)}>
                        <RemoveCircleOutlineIcon sx={{ color: "red" }} />
                      </IconButton>
                    </li> // Render the file name
                  ))}
                </ul>
              </Box>
            )}
          </Box>
          <Button type="submit" variant="contained" color="primary">
            {uploadStatus.message}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
PostItemDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  addNewProduct: PropTypes.func.isRequired,
  addNewService: PropTypes.func.isRequired,
};

export default PostItemDialog;
